import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import Chatbot from '../../chatbot/chatbot';
import ActionProvider from '../../chatbot/actionProvider';
import MessageParser from '../../chatbot/messageParser';
import config from '../../chatbot/config';


const PageLayout = ({ children }) => {
  const props = { ActionProvider, MessageParser, config };
  return (
      <>
      <Header />
      <div className="main-container">{children}</div>
      <Footer />
      <Chatbot {...props}></Chatbot>
      </>
  );
};
export default PageLayout;
