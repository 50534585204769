import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { isEnabledByRole } from '../Common/roleAnalyser';

const InsurerView = ({ onClose, open, data }) => {
  if (isEnabledByRole('quotemanagement-readquote')) {
    return (
      <Drawer title="View Detail" placement="right" onClose={onClose} open={open}>
        <div className="view_form">
          <label>Policy ID</label>
          <p>{data?.policyID}</p>
        </div>

        <div className="view_form">
          <label>User Name</label>
          <p>{data?.username}</p>
        </div>

        <div className="view_form">
          <label>User Email</label>
          <p>{data?.useremail}</p>
        </div>

        <div className="view_form">
          <label>Pet Name</label>
          <p>{data?.petName}</p>
        </div>

        <div className="view_form">
          <label>Pet Plan</label>
          <p>{data?.petPlan}</p>
        </div>

        <div className="view_form">
          <label>Breed</label>
          <p>{data?.petBreed}</p>
        </div>

        <div className="view_form">
          <label>Pet Sex</label>
          <p>Male</p>
        </div>

        <div className="view_form">
          <label>Pet Age</label>
          <p>4</p>
        </div>

        <div className="view_form">
          <label>Micro chip number</label>
          <p>45458784</p>
        </div>

        <div className="view_form">
          <label>Pet Category </label>
          <p>Category 1</p>
        </div>

        <div className="view_form">
          <label>Premium </label>
          <p>Yes</p>
        </div>

        <div className="view_form">
          <label>Sgst </label>
          <p>10%</p>
        </div>

        <div className="view_form">
          <label>cgst </label>
          <p>10%</p>
        </div>

        <div className="view_form">
          <label>Final Premium </label>
          <p>25,000</p>
        </div>

        <div className="view_form">
          <label>Payment Status </label>
          <p>Paid - 29-12-2023 10:30 AM</p>
        </div>
      </Drawer>
    );
  }
  return null;
};

export default InsurerView;
