import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Tag, Space, message, Switch, Popconfirm } from "antd";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import View from "./insurerView";
import Axios from '../Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function Insurer() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [insurers, setInsurers] = useState(null);
  const [view, setView] = useState(null);

  const showDrawer = (data) => {
    setOpen(true);
    setView(data)
  };

  const onClose = () => {
    setOpen(false);
    setView(null)
  };

  const confirm = (insurerId) => {
    axios.delete('insurer/' + insurerId).then(() => {
      message.success(`Successfully deleted`);
      setTimeout(() => {
        navigate(0)
      }, 2000);

    }).catch(err => {
      message.error(`Delete failed`);
      console.error(err)
    });
  };

  const cancel = () => {
    message.info(`Delete request cancelled`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => confirm(record.key)}
              onCancel={() => cancel()}
              okText="Yes"
              cancelText="No"
            >
              {isEnabledByRole('insurermanagement-deleteinsurer') &&
                <Button type="primary" danger>
                  Delete
                </Button>
              }
            </Popconfirm>

            {isEnabledByRole('insurermanagement-updateinsurer') &&
              <Button type="primary" onClick={() => navigate("/insurer/" + record.key)}>
                Edit
              </Button>
            }

            {isEnabledByRole('insurermanagement-readinsurer') &&
              <Button
                type="primary"
                className="view_button"
                onClick={() => showDrawer(record)}
              >
                View
              </Button>
            }

          </Space>
        )
      }
    },
  ];

  const onChange = (id, status) => {
    axios.put('insurer/' + id, { status }).then((response) => {
      if (response) {
        message.success(`Updated successfully`);
      }
    }).catch(err => {
      message.error(`Unable to get Insurers`);
      console.error(err);
    });
  };
  useEffect(() => {
    axios.get('insurer').then((response) => {
      if (response?.data?.allInsurers) {
        const data = response?.data?.allInsurers.map(x => {
          return {
            key: x._id,
            name: x.insuranceName,
            email: x.InsuranceemailId,
            type: x.businessType,
            status: <Switch defaultChecked={x.status ?? false}
              checkedChildren="On" unCheckedChildren="Off" onChange={(value) => onChange(x._id, value)} />,
          }
        })
        setInsurers(data);
      }
    }).catch(err => {
      message.error(`Unable to get Insurers`);
      console.error(err);
    });

  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0 }}>Insurer Management</h2>
        <div>
          {isEnabledByRole('insurermanagement-createinsurer') &&
            <Button onClick={() => navigate("/insurer/add")} type="primary">
              Add New
            </Button>
          }
        </div>
      </Flex>
      <Table columns={columns} dataSource={insurers} />
      {isEnabledByRole('insurermanagement-readinsurer') &&
        <View onClose={onClose} open={open} data={view} />
      }
    </>
  );
}

export default Insurer;
