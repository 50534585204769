import React from "react";
import type, { CollapseProps } from "antd";
import { Collapse, Row, Col } from "antd";

const FaqApi = [
  {
    key: 1,
    label: "Identification of Pet",
    children: (
      <p>
        1. Photographs/ Videos and/ or <br /> 2. Tagging (digital/non digital
        etc.) <br /> Colour Photo of RFID tag with the Identification Number
        clearly visible (Discount of 5% in case of RFID tagging).
      </p>
    ),
  },
  {
    key: 2,
    label: "Waiting Periods for Fresh Proposals",
    children: (
      <ul class="ul_style">
        <li>
          {" "}
          20 days for any claim for illnesses under any of Surgery,
          Hospitalization, Terminal diseases, Long-term care, OPD & Mortality
          benefit cover.
        </li>
        <li>45 days for any claim under Theft/Lost/Straying cover.</li>
        <li>
          No waiting periods are applicable on any claim under Third party
          liability cover and any claim arising due to an accident.
        </li>
      </ul>
    ),
  },
  {
    key: 3,
    label: "What if I am not sure about my pet's age?",
    children:
      "If you do not know the exact date of birth of your pet, please use your best estimate based on your pet’s medical records or shelter/rescue documentation. We will validate the correct age when reviewing your pet's medical records.",
  },
  {
    key: 4,
    label: "Claims Process:",
    children: (
      <ul class="ul_style">
        {" "}
        <li>
          Call our Claim Support Team +91 9940 224447 or register the claim with
          us at support@petzadore.in
        </li>{" "}
        <li>
          Our Claim Support Team will guide you through the documentation and
          help submit the claim form and supporting documents required.
        </li>{" "}
        <li>
          Once these documents are submitted, Insurer Claims team will
          scrutinuze the claim and determine admissibility
        </li>{" "}
        <li>Once the claim is approved, Claim payment will be processed.</li>{" "}
        <li>Claim paid.</li>{" "}
      </ul>
    ),
  },
  {
    key: 5,
    label: " Can I visit any veterinarian?",
    children: (
      <div>
        <p>
          Of course! We understand the importance of a trusting relationship
          between you and your veterinarian.
        </p>{" "}
        <p>
          If you have a Figo plan, you may visit any licensed veterinarian,
          worldwide. This includes specialists and emergency.
        </p>{" "}
        <p>
          <b>
            Is my coverage reduced for going to a specialist or emergency
            veterinary hospital?
          </b>
        </p>{" "}
        <p>
          No, while some companies will reduce your reimbursements for visiting
          a specialist, we do not. We will pay you whatever reimbursement
          percentage you currently have on your policy, after your deductible is
          met and copay is applied, toward any covered item on a specialist or
          emergency veterinary hospital bill.
        </p>{" "}
        <p>
          <b>
            Are there any special instructions for submitting my vet bills from
            another country?
          </b>
        </p>{" "}
        <p>
          When submitting foreign veterinary bills, please ensure the bill and
          medical records are translated into English; and the currency is
          displayed in US dollars as of the date of treatment.
        </p>{" "}
      </div>
    ),
  },
  {
    key: 6,
    label: "What documents are required for my pet's identification?",
    children: (
      <ul class="ul_style">
        <li>
          {" "}
          Videos of your pet walking and photos from all sides with date/time
          stamp
        </li>
        <li>Description/details which identifies your pet uniquely</li>
        <li>Color photo of the RFID tag, if available</li>
      </ul>
    ),
  },
  {
    key: 7,
    label: "How do I establish ownership of my pet?",
    children: (
      <ul class="ul_style">
        <li>
          A proof of purchase or a declaration would be sufficient
        </li>
      </ul>
    ),
  },
  {
    key: 8,
    label: "Do I have to provide any certificates?",
    children: (
      <ul className="ul_style">
        <li>Self declarations on vaccinations done on time</li>
        <li>Pedigree Certificate (in case of pedigree lineage)</li>
      </ul>
    ),
  },

];

const Faq = () => {
  return (
    <>
      {/* our work */}
      <div name="faq" className="d-inline-block w-100">
        <section className={"faq-section"}>
          <div className="container">
            <Row gutter={16}>
              <Col span={12}>
                <p className="text-primary mb-0">
                  <b>Questions ?</b>
                </p>
                <h2 className="h2-1">We have answer</h2>
                <p className="mb-5">
                  How can we help? We’d love to hear from you. <br /> We are
                  here to help and answer any questions you may have.
                </p>
              </Col>
              <Col span={12}>
                <Collapse accordion items={FaqApi} defaultActiveKey={["1"]} />
              </Col>
            </Row>
          </div>
        </section>
        <section class="bg-scroll-img">
          <div class="container">
            <div class="box"></div>
          </div>
        </section>
      </div>

      {/* our work */}
    </>
  );
};

export default Faq;
