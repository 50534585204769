import React from "react";
import { Link } from "react-router-dom";
import SiteConfig from "./SiteConfig";
import { Col, Row } from "antd";

const DownloadApp = () => {
  return (
    <>
      <section className="download-app-section" id="download-app">
        <div className="container">
          <div className="bg-box">
            <Row gutter={16} align="middle">
              <Col span={12}>
                <div className="box">
                  <p className="mb-2 text-primary">
                    <b>We are ready to help right away!</b>
                  </p>
                  <h2 className="h2-1">Download App</h2>
                  <ul className="ul_style mt-4">
                    <li>
                      Call our sales team - +91 9940 212424 any time between 9
                      AM to 6 PM
                    </li>
                    <li>Find our partner near you for support</li>
                    <li>
                      To report a claim - Please call +91 9940 224447 or write
                      to support@petzadore.in
                    </li>
                    <li>
                      Our claim support team will guide you throughout the claim
                      process to ensure you get the deserving claim amount
                      absolutely on time!
                    </li>
                  </ul>
                  <div className="app-download">
                    <a href={SiteConfig.apple} target="_blank">
                      <img src="images/appstore.png" />
                    </a>
                    <a href={SiteConfig.android} target="_blank">
                      <img src="images/playstore.png" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="download-padding">
                  <img src="images/mobile-app.png" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
