import React from "react";
import { Drawer } from "antd";
import { INR } from "../constants/currency";

const ProposalView = ({ onClose, open, data }) => {
  return (
    <Drawer title="View Detail" placement="right" onClose={onClose} open={open}>
      <div className="view_form">
        <label>Proposal Number</label>
        <p>{data.proposalNumber}</p>
      </div>
      <div className="view_form">
        <label>Enquiry Number</label>
        <p>{data.enquiryNumber}</p>
      </div>
      <div className="view_form">
        <label>Owner Name</label>
        <p>{data.ownerName}</p>
      </div>
      {/* <div className="view_form">
        <label>Owner Email</label>
        <p>{data.proposalNumber}</p>
      </div> */}

      <div className="view_form">
        <label>owner Contact Number</label>
        <p>{data.ownerCantactNo}</p>
      </div>

      <div className="view_form">
        <label>Pet Name</label>
        <p>{data.petName}</p>
      </div>

      <div className="view_form">
        <label>Status</label>
        <p>{data.status === 1 ? 'Active' : 'InActive'}</p>
      </div>

      <h4>Billing Information</h4>

      <div className="billing-flex">
        <p>
          Total Premium <span>{INR} {data.totalPremium}</span>
        </p>
        <p>
          SGST Amount <span>{INR} {data.sgstAmount}</span>
        </p>
        <p>
          CGST Amount <span>{INR} {data.cgstAmount}</span>
        </p>
        <p>
          iGST Amount <span>{INR} {data.igst}</span>
        </p>
        <p>
          Net Premium <span>{INR} {data.netPremium}</span>
        </p>
        <p>
          Final Premium <span>{INR} {data.finalPremium}</span>
        </p>
      </div>
    </Drawer>
  );
};
export default ProposalView;
