import React from "react";
import { Drawer } from "antd";

const PolicyStatusView = ({ onClose, open, data }) => {
  return (
    <Drawer title="View Detail" placement="right" onClose={onClose} open={open}>
      <div className="view_form">
        <label>Partner Code</label>
        <p>{data.partnerCode}</p>
      </div>
      <div className="view_form">
        <label>Partner Name</label>
        <p>{data.partnerName}</p>
      </div>
      <div className="view_form">
        <label>Store name</label>
        <p>{data.storeName}</p>
      </div>

      <div className="view_form">
        <label>Category</label>
        <p>{data.categoryName}</p>
      </div>

      <div className="view_form">
        <label>Email</label>
        <p>{data.emailId}</p>
      </div>
      <div className="view_form">
        <label>Phone Number</label>
        <p>{data.phoneNumber}</p>
      </div>

      <div className="view_form">
        <label>Address</label>
        <p>{data.addressLine1 + "," + data.addressLine2}</p>
      </div>
      <div className="view_form">
        <label>Pin Code</label>
        <p>{data.pinCode}</p>
      </div>
      <div className="view_form">
        <label>Referrer Code</label>
        <p>{data.referrerCode}</p>
      </div>
      <div className="view_form">
        <label>Status</label>
        <p>
          {data.status === 1
            ? "Pending Approval"
            : data.status === 2
            ? "Approved"
            : "Rejected"}
        </p>
      </div>
    </Drawer>
  );
};
export default PolicyStatusView;
