import React, { useState } from "react";
import {
  Flex,
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import Axios from "../Common/interceptor";
import { useParams } from "react-router";
import { isEnabledByRole } from "../Common/roleAnalyser";

function InsurerForm(props) {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [enableSubmit, setEnableSubmit] = useState(false);

  useState(() => {
    if (id) {
      axios
        .get("insurer/" + id)
        .then((response) => {
          if (response?.data?.insurer) {
            form.setFieldsValue({
              ...response.data.insurer,
            });
          }
        })
        .catch((err) => {
          message.error(`Unable to get the insurer details`);
          console.error(err);
        });

      setEnableSubmit(isEnabledByRole("insurermanagement-updateinsurer"));
    } else {
      setEnableSubmit(isEnabledByRole("insurermanagement-createinsurer"));
    }
  }, []);

  const onFinish = (values) => {
    if (id) {
      axios
        .put("insurer/" + id, values)
        .then((response) => {
          if (response) {
            message.success(`Updated successfully`);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        })
        .catch((err) => {
          message.error(`Unable to save the Insurer details.`);
          console.error(err);
        });
    } else {
      axios
        .post("insurer", values)
        .then((response) => {
          if (response) {
            message.success("Successfully saved");
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        })
        .catch((err) => {
          message.error(`Unable to save`);
          console.error(err);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <>
      <Row justify="center">
        <Col span="12">
          <Card title={id ? "Edit" : "Add New"}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Insurance Name"
                name="insuranceName"
                rules={[{ required: true, message: "Please input App Name" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Insurance Email"
                name="InsuranceemailId"
                rules={[
                  { required: true, message: "Please input Insurance Email" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input Password" }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item label="Business Type" name="businessType">
                <Select defaultValue="b2b">
                  <Select.Option value="b2b">
                    Business To Business
                  </Select.Option>
                  <Select.Option value="b2c">
                    Business To Customer
                  </Select.Option>
                </Select>
              </Form.Item>

              <Flex align="center" justify="center" gap="middle">
                <Button onClick={() => navigate(-1)}>Back</Button>
                {enableSubmit && (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                )}
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default InsurerForm;
