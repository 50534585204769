import React from "react";
import {
  PageLayout,
  Banner,
  WhatIsCovered,
  OurPlans,
  Documents,
  DownloadApp,
  Faq,
  WhyOurPetInsurance
} from "../../components/Home";

const Home = () => {
  return (
    <>
      <PageLayout>
          <Banner />
          <WhatIsCovered />
          <OurPlans />
          <Documents />
          <DownloadApp />
          <WhyOurPetInsurance />
          <Faq />
      </PageLayout>
    </>
  );
};
export default Home;
