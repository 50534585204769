import Layout from "./Common/layout";
import "./App.css";
import Login from "./Login";
import { Route, Routes, Navigate } from "react-router-dom";
import { AdminsList, CreateOrEditAdmin } from "./superadmin";
import ChangePassword from "./Common/changePassword";
import Management from "./rolemanagement/Management";
import MyProfile from "./Common/myprofile";
import UpdateProfile from "./Common/updateProfile";
import AdminSettings from "./Common/adminSettings";
import { Insurer, InsurerForm } from "./insurer";
import { Quote, QuoteForm } from "./quote";
import { PriceMatrix, PriceMatrixForm } from "./priceMatrix";
import { ActivityLog } from "./activityLog";
import { Home, NotFound, PartnerWithUs } from "./frontPage";
import { Enquiry, EnquiryForm } from "./enquiry";
import { PolicyStatus } from "./policyStatus";
import { Partners, PartnersForm } from "./partners";
import { ReferralSettingsForm } from "./referralSettings";
import { Proposal } from "./proposal";
import SMTP from "./Common/smtp";
import ResetPassword from "./Common/ResetPassword";
import ResetPasswordForm from "./Common/resetPasswordForm";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/partner-with-us" exact element={<PartnerWithUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/resetpasswordForm" element={<ResetPasswordForm />} />
        <Route path="/" element={<Layout />}>
          <Route path="/admin">
            <Route index element={<AdminsList />} />
            <Route path="add" element={<CreateOrEditAdmin />} />
            <Route path=":adminid" element={<CreateOrEditAdmin />} />
          </Route>
          <Route path="/roles">
            <Route index element={<Management />} />
          </Route>
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/smtp" element={<SMTP />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/settings" element={<AdminSettings />} />
          <Route path="/insurer">
            <Route index element={<Insurer />} />
            <Route path="add" element={<InsurerForm />} />
            <Route path=":id" element={<InsurerForm />} />
          </Route>
          <Route path="/quote">
            <Route index element={<Quote />} />
            <Route path="add" element={<QuoteForm />} />
            <Route path=":id" element={<QuoteForm />} />
          </Route>
          <Route path="/price-matrix">
            <Route index element={<PriceMatrix />} />
            <Route path="add" element={<PriceMatrixForm />} />
            <Route path=":id" element={<PriceMatrixForm />} />
          </Route>
          <Route path="/enquiry">
            <Route index element={<Enquiry />} />
            <Route path="add" element={<EnquiryForm />} />
            <Route path=":id" element={<EnquiryForm />} />
          </Route>
          <Route path="/" element={<Navigate to="/insurer" />} />
          <Route path="/activity-log">
            <Route index element={<ActivityLog />} />
          </Route>
          <Route path="/policy-status">
            <Route index element={<PolicyStatus />} />
          </Route>
          <Route path="/partners">
            <Route index element={<Partners />} />
            <Route path="add" element={<PartnersForm />} />
            <Route path="edit/:id" element={<PartnersForm />} />
          </Route>
          <Route path="/referral-settings">
            <Route index element={<ReferralSettingsForm />} />
            <Route path="add" element={<ReferralSettingsForm />} />
          </Route>
          <Route path="/proposal">
            <Route index element={<Proposal />} />
          </Route>
        </Route>
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
