import React from "react";
import { Link } from "react-router-dom";
import SiteConfig from "./SiteConfig";
import { Button } from 'antd';

const Banner = () => {
  return (
    <>
      {/* home banner */}
      <section className="banner" name="home" id="home">
        <div className="container">
          <div className="banner-content">

          {/*logo  */}
          <div className="logo">
            <Link to="/">
              <img
                alt={"Petzadore"}
                height="85"
                src={"images/logo.png"}
                width="263"
              />
            </Link>
          </div>
          {/*logo  */}

            <p className="save">Comprehensive Pet Insurance Plans</p>
            <h1>
            Let your <span>love</span> meet protection
            </h1>
            <div className="head-line">
              <img src="images/head_line.png" />
            </div>
            <p className="width-reduce">
              Give your pets the same care you'd give to a beloved family member
            </p>
            <div className="button-flex">
              <Button className="btn btn-color">
                Get a Quote
              </Button>
              <Button className="btn btn-border">
                Call us {SiteConfig.call}
              </Button>
            </div>
            <p className="para-small">
            *having insurance for your pet enables you a worry free pet care, where you will be able to foot most of your pet's healthcare costs from the insurance! You will be able to make your spend in the best interest of your pet's health not bothering much about your financial constraints. Insure your pets and stay worry free!
            </p>
          </div>
        </div>
      </section>

      {/* home banner */}
    </>
  );
};

export default Banner;
