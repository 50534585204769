import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import Axios from "../../src/Common/interceptor";

function Partners() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [referralSetting, setReferralSetting] = useState([]);

  useEffect(() => {
    axios
      .get("referrersetting")
      .then((response) => {
        if (response?.data?.referrersetting) {
          setReferralSetting([{ ...response.data.referrersetting, key: response.data.referrersetting._id }]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const columns = [
    {
      title: "Store Referral Percentage",
      dataIndex: "petStoreReferrerPercentage",
      key: "petStoreReferrerPercentage",
    },
    {
      title: "Customer Referral Percentage",
      dataIndex: "customerReferrerPercentage",
      key: "customerReferrerPercentage",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">

          <Button
            type="primary"
            onClick={() => navigate('/referral-settings/edit')}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      petstorePercentage: "25%",
      customerPercentage: "10%",
      status: 1,
    },
    {
      key: "2",
      petstorePercentage: "25%",
      customerPercentage: "10%",
      status: 1,
    },
    {
      key: "3",
      petstorePercentage: "25%",
      customerPercentage: "10%",
      status: 1,
    },
    {
      key: "4",
      petstorePercentage: "25%",
      customerPercentage: "10%",
      status: 1,
    },
  ];
  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0 }}>Referral Settings</h2>
        <Button
          type="primary"
          onClick={() => navigate("/referral-settings/add")}
        >
          Add New
        </Button>
      </Flex>
      <Table columns={columns} dataSource={referralSetting} />
    </>
  );
}
export default Partners;
