import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Tag, Space, message, Switch, Popconfirm } from "antd";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import View from "./quoteView";
import Axios from '../Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function Quote() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [quotes, setQuotes] = useState(null);
  const [view, setView] = useState(null);

  const showDrawer = (data) => {
    setOpen(true);
    axios.get('quote/' + data.key).then((response) => {
      if (response.data.quote) {
        setView(response.data.quote);
      }
    }).catch(err => {
      message.error(`Unable to save the Quote details.`);
      console.error(err);
    });
  };

  const onClose = () => {
    setOpen(false);
    setView(null);
  };

  const confirm = (quoteId) => {
    axios.delete('quote/' + quoteId).then(() => {
      message.success(`Successfully deleted`);
      setTimeout(() => {
        navigate(0)
      }, 2000);

    }).catch(err => {
      message.error(`Delete failed`);
      console.error(err)
    });
  };

  const cancel = () => {
    message.info(`Delete request cancelled`);
  };


  const columns = [
    {
      title: "Policy ID",
      dataIndex: "policyID",
      key: "policyID",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "User Email",
      dataIndex: "useremail",
      key: "useremail",
    },
    {
      title: "Pet Name",
      dataIndex: "petName",
      key: "petName",
    },
    {
      title: "Pet Plan",
      dataIndex: "petPlan",
      key: "petPlan",
    },
    {
      title: "Breed",
      dataIndex: "petBreed",
      key: "petBreed",
    },
    {
      title: "Category",
      dataIndex: "petCategory",
      key: "petCategory",
    },
    {
      title: "Premium",
      dataIndex: "netPremium",
      key: "netPremium",
    },
    {
      title: "Payment Status",
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (_, { paymentStatus }) => (
        <>
          <Tag color={paymentStatus === "Not Paid" ? "red" : "green"}>{
            paymentStatus == "1" ? "Pending" : (paymentStatus == "2" ? "Success" : "Failure")
          }</Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => confirm(record.key)}
            onCancel={() => cancel()}
            okText="Yes"
            cancelText="No"
          >
            {isEnabledByRole('quotemanagement-deletequote') &&
              <Button type="primary" danger>
                Delete
              </Button>
            }
          </Popconfirm>

          {isEnabledByRole('quotemanagement-updatequote') &&
            <Button type="primary" onClick={() => navigate("/quote/" + record.key)}>
              Edit
            </Button>
          }
          {isEnabledByRole('quotemanagement-readquote') &&
            <Button
              type="primary"
              className="view_button"
              onClick={() => showDrawer(record)}
            >
              View
            </Button>
          }
        </Space>
      ),
    },
  ];

  useEffect(() => {
    axios.get('quote').then((response) => {
      if (response?.data?.allQuotes) {
        const data = response?.data?.allQuotes.map(x => {
          console.log(x);
          return {
            key: x._id,
            policyID: x.policyID,
            username: x.username,
            useremail: x.useremailId,
            petName: x.petName,
            petPlan: x.petPlan,
            petBreed: x.petBreed,
            petCategory: x.petCategory,
            netPremium: x.netPremium,
            paymentStatus: x.paymentStatus,
          }
        })
        setQuotes(data);
      }
    }).catch(err => {
      message.error(`Unable to get Insurers`);
      console.error(err);
    });

  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0 }}>Quote Management</h2>
        <div>
          {
            isEnabledByRole('quotemanagement-createquote') &&
            <Button onClick={() => navigate("/quote/add")} type="primary">
              Add New
            </Button>
          }
        </div>
      </Flex>
      {
        isEnabledByRole('quotemanagement-readquote') &&
        <Table columns={columns} dataSource={quotes} scroll={{ x: 1600 }} />
      }
      <View onClose={onClose} open={open} data={view} />
    </>
  );
}

export default Quote;
