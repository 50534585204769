import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { isEnabledByRole } from '../Common/roleAnalyser';

const EnquiryView = ({ onClose, open, data }) => {
  if (isEnabledByRole('enquiry-readenquiry')) {
    return (
      <Drawer
        title="View Detail"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="view_form">
          <label>Owner Name</label>
          <p>{data?.ownerName}</p>
        </div>

        <div className="view_form">
          <label>Owner's Age</label>
          <p>{data?.ownerAge}</p>
        </div>

        <div className="view_form">
          <label>Contact Number</label>
          <p>{data?.ownerCantactNo}</p>
        </div>
        <div className="view_form">
          <label>Pin Code</label>
          <p>{data?.pinCode}</p>
        </div>
        <div className="view_form">
          <label>Pet Type</label>
          <p>{data?.petType ? "Cat" : "Dog"}</p>
        </div>
        <div className="view_form">
          <label>Pet Name</label>
          <p>{data?.petName}</p>
        </div>
        <div className="view_form">
          <label>Pet Gender</label>
          <p>{data?.petGender}</p>
        </div>
        <div className="view_form">
          <label>Pet Age</label>
          <p>{data?.petAge}</p>
        </div>
        <div className="view_form">
          <label>Pet Breed</label>
          <p>{data?.petBreed}</p>
        </div>

        <div className="view_form">
          <label>Registered or Unregistered</label>
          <p>
            {data?.registeredOrUnregistered ? "Registered" : "NotRegistered"}
          </p>
        </div>

        <div className="view_form">
          <label>Vaccinated Or Not</label>
          <p>{data?.vaccinatedOrNot ? "Vaccinated" : "NotVaccinated"}</p>
        </div>

        <div className="view_form">
          <label>Referrer Code</label>
          <p>{data?.referrerCode}</p>
        </div>
        <div className="view_form">
          <label>Status</label>
          <p>{data?.status ? "Active" : "InActive"}</p>
        </div>
      </Drawer>
    );
  }
  return null;
};

export default EnquiryView;
