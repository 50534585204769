import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { isEnabledByRole } from '../Common/roleAnalyser';

const InsurerView = ({ onClose, open, data }) => {

  if (isEnabledByRole('insurermanagement-updateinsurer')) {
    return (
      < Drawer title="View Detail" placement="right" onClose={onClose} open={open} >
        <div className="view_form">
          <label>Name</label>
          <p>{data?.name}</p>
        </div>

        <div className="view_form">
          <label>Email</label>
          <p>{data?.email}</p>
        </div>

        <div className="view_form">
          <label>Business Type</label>
          <p>{data?.type}</p>
        </div>
      </Drawer >
    );
  }
  return null
};

export default InsurerView;
