import React from 'react';

const CustomBotAvatar = () => {
  return (
    <img
      src='images/picon.png'
      alt='Bot Avatar'
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '10px',
      }}
    />
  );
};

export default CustomBotAvatar;
