import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import Axios from "../Common/interceptor";

function PartnersForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  useEffect(() => {
    if (id) {
      axios
        .get("partner/" + id)
        .then((response) => {
          console.log(response);
          if (response?.data?.partner) {
            form.setFieldsValue({
              ...response.data.partner[0],
              status: String(response.data.partner[0].status),
            });
          }
        })
        .catch((err) => {
          message.error(`Unable to get the Partner details`);
          console.error(err);
        });
    }
  }, [id]);

  const submitForm = async () => {
    try {
      const formData = await form.validateFields();
      setLoading(true);
      const response = await axios.post("/partner", formData);
      if (response.status === 201) {
        message.success(
          "Thank you for submitting your details. Once your registration is approved, you will be intimated through email for activation"
        );
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-content">
        <h3>Partner With us</h3>
        <Card title={"Add New Partner"}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="partnerName"
              rules={[{ required: true, message: "Please enter your Name" }]}
            >
              <Input
                prefix={<i className="mdi mdi-account-outline" />}
                placeholder="Name"
              />
            </Form.Item>

            <Form.Item
              name="storeName"
              rules={[
                { required: true, message: "Please enter your Store Name" },
              ]}
            >
              <Input
                prefix={<i className="mdi mdi-office-building-outline" />}
                placeholder="Store / Establishment Name"
              />
            </Form.Item>

            <Form.Item
              name="categoryId"
              rules={[
                { required: true, message: "Please select any category" },
              ]}
            >
              <Select placeholder="Select Category">
                <Select.Option value="petstore">Pet Store</Select.Option>
                <Select.Option value="vet">Vet</Select.Option>
                <Select.Option value="trainer">Trainer</Select.Option>
                <Select.Option value="groomer">Groomer</Select.Option>
                <Select.Option value="breeder">Breeder</Select.Option>
                <Select.Option value="others">Others</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="emailId"
              rules={[{ required: true, message: "Please enter your EmailId" }]}
            >
              <Input
                prefix={<i className="mdi mdi-email-outline" />}
                placeholder="Email Address"
              />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter your Phone Number",
                },
              ]}
            >
              <Input
                prefix={<i className="mdi mdi-phone-in-talk-outline" />}
                placeholder="Phone Number"
              />
            </Form.Item>

            <Form.Item
              name="addressLine1"
              rules={[{ required: true, message: "Please enter your Address" }]}
            >
              <Input
                prefix={<i className="mdi mdi-map-marker-outline" />}
                placeholder="Address line 1"
              />
            </Form.Item>

            <Form.Item name="addressLine2">
              <Input
                prefix={<i className="mdi mdi-map-marker-outline" />}
                placeholder="Address line 2"
              />
            </Form.Item>

            <Form.Item
              name="city"
              rules={[{ required: true, message: "Please enter your city" }]}
            >
              <Input
                prefix={<i className="mdi mdi-map-marker-outline" />}
                placeholder="City"
              />
            </Form.Item>

            <Form.Item
              name="pinCode"
              rules={[
                { required: true, message: "Please enter your Pin Code" },
              ]}
            >
              <Input
                prefix={<i className="mdi mdi-map-marker-outline" />}
                placeholder="Pincode"
              />
            </Form.Item>

            <Form.Item
              name="country"
              rules={[{ required: true, message: "Please enter your County" }]}
            >
              <Input
                prefix={<i className="mdi mdi-map-marker-outline" />}
                placeholder="Country"
              />
            </Form.Item>

            <Button
              block
              type="primary"
              htmlType="button"
              onClick={submitForm}
              loading={loading}
            >
              Submit
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
}
export default PartnersForm;
