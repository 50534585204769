import React, { useEffect } from 'react';
import { useState } from "react";
import { Row, Col, message } from 'antd';
import { Button, Card, Input, Select, Radio, Switch, Modal, Form, Checkbox, Space, Icon } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Axios from './interceptor';
import { useNavigate } from "react-router-dom";
import { isEnabledByRole } from '../Common/roleAnalyser';

function SMTP() {

    const navigate = useNavigate();
    const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (!values.smtpEncryption) {
            values.smtpEncryption = 'tls'
        }
        console.log(values);
        axios.put('smtp', values).then((response) => {
            if (response?.data) {
                message.success(`Successfully updated`);
                setTimeout(() => {
                    navigate(0);
                }, 2000);
            }

        }).catch(err => {
            console.error(err);
            message.error(`Unable to get smtp details`);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        axios.get('smtp').then((response) => {
            if (response?.data) {
                if (response.data.smtp !== "") {
                    console.log('Fill the fields', response.data.smtp);
                    const { smtpHost,
                        smtpEncryption,
                        smtpPort,
                        smtpUsername,
                        smtpPassword } = response.data.smtp
                    form.setFieldsValue({
                        smtpHost,
                        smtpEncryption,
                        smtpPort,
                        smtpUsername,
                        smtpPassword,
                    });
                }
            }
        }).catch(err => {
            console.error(err);
            message.error(`Unable to get smtp details`);
        });

    }, []);

    const initialValues = { remember: true, username: 'ravisuper', password: '03611a0433' };
    return (
        <Row justify="center">
            <Col span={12}>
                <Form form={form} initialValues={initialValues} onFinish={onFinish}
                    onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
                    <Card title="SMTP">
                        <Form.Item
                            label="Smtp Host"
                            name="smtpHost"
                            rules={[
                                { required: true, message: 'Please input Smtp Host' }
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Encryption"
                            name="smtpEncryption"
                        >
                            <Select
                                defaultValue="tls"
                                value="tls"
                                options={[
                                    { value: "tls", label: 'tls' },
                                    { value: "ssl", label: 'ssl' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Smtp Port"
                            name="smtpPort"
                            rules={[
                                { required: true, message: 'Please input Smtp Port' }
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Smtp Username"
                            name="smtpUsername"
                            rules={[
                                { required: true, message: 'Please input Smtp Username' }
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Smtp Password"
                            name="smtpPassword"
                            rules={[
                                { required: true, message: 'Please input Password!' },
                                { min: 4, message: 'Password must be minimum 8 characters.' },
                                { max: 16, message: 'Password cannot exceed 16 characters.' }
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="Is Smtp Enabled"
                            name="issmtpEnabled"
                        >
                            <Switch defaultChecked={true}
                                checkedChildren="On" unCheckedChildren="Off" onChange={(value) => { }} />
                        </Form.Item>
                        {isEnabledByRole('smtpmanagement-updatesmtp') &&
                            <Button type='primary' htmlType="submit" block>
                                Submit
                            </Button>
                        }
                    </Card>
                </Form>
            </Col>
        </Row>
    );
}

export default SMTP