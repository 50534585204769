import React from "react";
import { Row, Col, message } from "antd";
import { Button, Card, Input, Form } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Axios from "./interceptor";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ResetPasswordForm() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const onFinish = (values) => {
    const { newPassword, confirmPassword } = values;
    const body = { newPassword,confirmPassword };
    body.resetToken = token;
    axios
      .post("resetpasswordchange", body)
      .then((response) => {
        if (response) {
          console.log("response :: ", response);
          message.success(`Successfully changed Password.`);
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        console.error(err);
        message.error(`Could not change password.`);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const initialValues = {
    remember: true,
    username: "ravisuper",
    password: "03611a0433",
  };
  return (
    <Form
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Row
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          background: "#eee",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Card
            title="Change Password"
            style={{ width: "100%", maxWidth: "560px" }}
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please input new Password!" },
                {
                  min: 8,
                  message: "New Password must be minimum 8 characters.",
                },
                {
                  max: 16,
                  message: "New Password cannot exceed 16 characters.",
                },
              ]}
            >
              <Input.Password
                placeholder="New Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: "Please input confirm Password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>

            <Button
              block
              onClick={() => navigate(-1)}
              style={{ marginTop: "20px" }}
            >
              Back
            </Button>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default ResetPasswordForm;
