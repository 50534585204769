import React, { useState } from 'react';
import Axios from '../Common/interceptor';

function MyProfile() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [user, setUser] = useState(null);

  useState(() => {
    axios.get('accountprofile').then((response) => {
      if (response?.data?.user) {
        console.log(response.data.user);


        switch (response.data.user.userType) {
          case 1:
            response.data.user.userType = 'SuperAdmin'
            break;
          case 2:
            response.data.user.userType = 'Admin'
            break;
          case 3:
            response.data.user.userType = 'Manager'
            break;
          case 4:
            response.data.user.userType = 'Executive'
            break;
          default:
            break;
        }

        setUser(response.data.user);
      }
    }).catch(err => {
      // message.error(`Unable to save role.`);
      console.error(err);
    });
  }, [])

  return (
    <>
    <h2>Profile</h2>
    <table className="myprofile-table">
      <tr>
        <td>User Name</td>
        <td><b>{user && user.userName}</b></td>
      </tr>
      <tr>
        <td>First Name</td>
        <td><b>{user && user.firstName}</b></td>
      </tr>
      <tr>
        <td>Last Name</td>
        <td><b>{user && user.lastName}</b></td>
      </tr>
      <tr>
        <td>User Type</td>
        <td><b>{user && user.userType}</b></td>
      </tr>
    </table>
    </>
  )
}

export default MyProfile