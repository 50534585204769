import React, { useEffect, useState } from 'react';
import { Space, Table, Row, Col, Button, message, Popconfirm } from 'antd';
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Axios from '../../src/Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function AdminsList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const confirm = (record) => {
    axios.delete('admin/' + record.key).then(() => {
      message.success(`Successfully deleted ${record.fname} ${record.lname}`);
      setTimeout(() => {
        navigate(0)
      }, 2000);

    }).catch(err => {
      message.error(`Delete failed for ${record.fname} ${record.lname}`);
      console.error(err)
    });
  };

  const cancel = (record) => {
    message.info(`Delete request cancelled for ${record.fname} ${record.lname}`);
  };

  const columns = [
    {
      title: 'FirstName',
      dataIndex: 'fname',
      key: 'fname',
    },
    {
      title: 'MiddleName',
      dataIndex: 'mname',
      key: 'mname',
    },
    {
      title: 'LastName',
      dataIndex: 'lname',
      key: 'lname',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
          return (
            <Space size="middle">
              {isEnabledByRole('adminmanagement-updateadmin') &&
                <NavLink
                  key={record.key + 'edit'}
                  to={"/admin/" + record.key}
                >
                  Edit
                </NavLink>
              }

              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={() => confirm(record)}
                onCancel={() => cancel(record)}
                okText="Yes"
                cancelText="No"
              >
                {isEnabledByRole('adminmanagement-deleteadmin') &&
                  <NavLink>Delete </NavLink>}
              </Popconfirm>
            </Space>
          )
      }
    },
  ];
  // let data = [];

  useEffect(() => {
    axios.get('admin').then((response) => {
      if (response) {
        const admdata = response?.data?.admins?.map(x => {
          return { key: x._id, fname: x.firstName, mname: x.middleName, lname: x.lastName }
        });
        setData(admdata);
      }
    }).catch(err => {
      console.error(err)
    });
  }, []);


  return (
    <>
      <Row gutter={[8, 8]} justify={"end"}>
        <Col span={2}>
          {isEnabledByRole('adminmanagement-createadmin') && <Button onClick={() => navigate("./add")} block >
            Add
          </Button>}
        </Col>
      </Row>
      <br></br>
      {
      isEnabledByRole('adminmanagement-readadmin') &&
      <Table dataSource={data} columns={columns} />
}
    </>
  )
}

export default AdminsList