import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Button, Card, Input, Form, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Axios from '../Common/interceptor';
import { useNavigate } from "react-router-dom";
import { isEnabledByRole } from '../Common/roleAnalyser';

function CreateOrEditAdmin() {
    let { adminid } = useParams();
    const [title, setTitle] = useState('Create Admin');
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        console.log('adminid :: ', adminid);
        if (adminid) {
            axios.get('admin/' + adminid).then((response) => {
                if (response) {
                    console.log(response.data.user);
                    const { firstName, lastName, middleName } = response.data.user;
                    setTitle('Edit ' + firstName + " " + lastName);
                    setIsEdit(true);
                    form.setFieldsValue({ firstName, middleName, lastName })
                }
            }).catch(err => console.error(err));

            setEnableSubmit(isEnabledByRole('adminmanagement-updateadmin'))
        } else {
            setEnableSubmit(isEnabledByRole('adminmanagement-createadmin'))
        }
    }, [])

    const onFinish = (values) => {
        const body = {
            userName: values.userName,
            passWord: values.password,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName
        };
        if (!isEdit) {
            console.log('In Create');
            axios.post('admin', body).then(() => {
                message.success(`Successfully Added ${body.firstName} ${body.lastName}`);
                navigate("../")
            }).catch(err => {
                message.error(`Create failed`);
                console.error(err)
            });
        } else {
            console.log('In Edit', body);
            axios.put('admin/' + adminid, body).then(() => {
                message.success(`Successfully edited ${body.firstName} ${body.lastName}`);
                navigate("../")
            }).catch(err => {
                message.error(`Create failed`);
                console.error(err)
            });

        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm();
    const initialValues = { firstName: null, middleName: null, lastName: null, password: null };

    return (
        <Row justify={"center"}>
            <Col span={10}>
                <Card title={title}>
                    <Form form={form} initialValues={initialValues} onFinish={onFinish}
                        onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
                        <Form.Item
                            label="FirstName"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input FirstName !' }]}
                        >
                            <Input placeholder='FirstName' prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="MiddleName"
                            name="middleName"
                        >
                            <Input placeholder='MiddleName' prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item
                            label="LastName"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input LastName !' }]}
                        >
                            <Input placeholder='LastName' prefix={<UserOutlined />} />
                        </Form.Item>
                        {!isEdit &&
                            <>
                                <Form.Item
                                    label="UserName"
                                    name="userName"
                                    rules={[{ required: true, type: "email", message: 'Please input UserName(Email) !' }]}
                                >
                                    <Input placeholder='UserName(Email)' prefix={<UserOutlined />} />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>


                                {/* <Form.Item
                            label="CnfPassword"
                            name="cnfPassword"
                            rules={[{ required: true, message: 'Please confirm Password!' }]}
                        >
                            <Input type="password" placeholder='Confirm Password' prefix={<LockOutlined />} />
                        </Form.Item> */}
                                <Form.Item
                                    name="cnfPassword"
                                    label="CnfPassword"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>
                            </>
                        }
                        <Row gutter={[8, 8]} justify={"end"}>
                            <Col span={6}>
                                <Button block onClick={() => navigate("../")} >
                                    Cancel
                                </Button>
                            </Col>
                            <Col span={6}>
                                { enableSubmit &&
                                    <Button block type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default CreateOrEditAdmin