import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Tag,
  Space,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message,
} from "antd";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Axios from "../Common/interceptor";
import { isEnabledByRole } from "../Common/roleAnalyser";
import moment from "moment";

function EnquiryForm() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [enableSubmit, setEnableSubmit] = useState(false);
  console.log(moment().format("DD-MM-YYYY H:mm"));
  useState(() => {
    if (id) {
      axios
        .get("enquiry/" + id)
        .then((response) => {
          if (response?.data?.enquiry) {
            form.setFieldsValue({
              ...response.data.enquiry,
            });
          }
        })
        .catch((err) => {
          message.error(`Unable to get the Enquiry details`);
          console.error(err);
        });
      setEnableSubmit(isEnabledByRole("enquiry-updateenquiry"));
    } else {
      setEnableSubmit(isEnabledByRole("enquiry-createenquiry"));
    }
  }, []);

  const onFinish = (values) => {
    if (id) {
      axios
        .put("enquiry/" + id, values)
        .then((response) => {
          if (response) {
            message.success(`Updated successfully`);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        })
        .catch((err) => {
          message.error(`Unable to save the Enquiry details.`);
          console.error(err);
        });
    } else {
      values.enquiryDate = moment().format("DD-MM-YYYY H:mm");
      console.log("values for saving :: ", values);
      axios
        .post("enquiry", values)
        .then((response) => {
          if (response) {
            message.success("Successfully saved");
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        })
        .catch((err) => {
          message.error(`Unable to save`);
          console.error(err);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  console.log(form);

  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <Card title={id ? "Edit" : "Add New"}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Owner Name"
                name="ownerName"
                rules={[{ required: true, message: "Please input Owner Name" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Owner Age"
                name="ownerAge"
                rules={[
                  {
                    required: true,
                    message: "Please input Owner Age",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input maxLength={2} />
              </Form.Item>

              <Form.Item
                label="Owner Contact Number"
                name="ownerCantactNo"
                rules={[
                  {
                    required: true,
                    message: "Please input Owner contact number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>

              <Form.Item
                label="Pet Type"
                name="petType"
                rules={[{ required: true, message: "Please input Pet Type" }]}
              >
                <Select>
                  <Select.Option value="1">Dog</Select.Option>
                  <Select.Option value="2">Cat</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Pet Name"
                name="petName"
                rules={[{ required: true, message: "Please input Pet Name" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Pet Gender"
                name="petGender"
                rules={[{ required: true, message: "Please input Gender" }]}
              >
                <Select>
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value="Female">Female</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Pet Age"
                name="petAge"
                rules={[
                  {
                    required: true,
                    message: "Please input Pet Age",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input maxLength={2} />
              </Form.Item>

              <Form.Item
                label="Pet Breed"
                name="petBreed"
                rules={[{ required: true, message: "Please input Pet Breed" }]}
              >
                <Select>
                  <Select.Option value="Breed1">Breed1</Select.Option>
                  <Select.Option value="Breed2">Breed2</Select.Option>
                  <Select.Option value="Breed3">Breed3</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="RegisteredOrUnregistered"
                name="registeredOrUnregistered"
                rules={[{ required: true, message: "Please Yes or No" }]}
              >
                <Select>
                  <Select.Option value="1">yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="VaccinatedOrNot"
                name="vaccinatedOrNot"
                rules={[{ required: true, message: "Please input Yes or No" }]}
              >
                <Select>
                  <Select.Option value="1">yes</Select.Option>
                  <Select.Option value="0">No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Pincode"
                name="pinCode"
                rules={[
                  {
                    required: true,
                    message: "Please input Pincode",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input maxLength={6} />
              </Form.Item>

              <Flex
                align="center"
                justify="center"
                gap="middle"
                style={{ marginTop: 25 }}
              >
                <Button onClick={() => navigate(-1)}>Back</Button>
                {enableSubmit && (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                )}
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default EnquiryForm;
