import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from 'antd';

const OurPlans = () => {
  return (
    <>
      <section className="our-services" id="plans" name="plans">
        <div className="container">
          <div className="text-center mb-5">
            <p className="badge-1 mb-0 text-primary">
              <b>Our Plans</b>
            </p>
            <h2 className="h2-1">Find the best plan for your pet</h2>
            <p className="mb-0">
              Pet insurance that fits into your life, not the other way around.
            </p>
          </div>
          <Row gutter={16} justify="center">
            <Col span={6}>
              <div className="box">
                <div className="img">
                  <img src="images/dog.png" />
                </div>
                <h3>Dog</h3>
                <p>
                  From <br /> <b>₹450/mo</b>
                </p>
              </div>
            </Col>
            <Col span={6}>
              <div className="box">
                <div className="img">
                  <img src="images/cat.png" />
                </div>
                <h3>Cat</h3>
                <p>
                  From <br /> <b>₹325/mo</b>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* eligible */}
    </>
  );
};

export default OurPlans;
