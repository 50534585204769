import React, { useEffect, useState } from "react";
import TreeControl from "../../components/TreeControl";
import { Row, Col, Button, message, Input, Form, Card } from "antd";
import { useNavigate } from "react-router-dom";
import Axios from "../../../src/Common/interceptor";
import PropTypes from "prop-types";
const roleSkeleton = require("../../constants/roles.json");

function AddOrEditRole(props) {
  const { id, rolename, permissions: pforEdit, cancelAddOrEdit } = props;
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [permissions, setPermissions] = useState(pforEdit);

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
    setPermissions(checkedKeys);
  };

  const onFinish = (values) => {
    const { rolename } = values;
    if (id) {
      axios
        .put("roles/" + id, { rolename, permissions })
        .then((response) => {
          if (response) {
            navigate(0);
          }
        })
        .catch((err) => {
          message.error(`Unable to save the Role.`);
          console.error(err);
        });
    } else {
      axios
        .post("roles", { rolename, permissions })
        .then((response) => {
          if (response) {
            navigate(0);
          }
        })
        .catch((err) => {
          message.error(`Unable to save the Role.`);
          console.error(err);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onCancelClick = () => {
    cancelAddOrEdit();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        rolename: rolename,
      });
    }
  }, []);

  const initialValues = {};

  const table_props = {
    treeData: roleSkeleton,
    onSelectChanged: onSelect,
    onCheckedChanged: onCheck,
    defaultCheckedKeys: permissions,
  };
  return (
    <Row justify="center" style={{ marginTop: 25 }}>
      <Col span={12}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Card>
            <Form.Item
              label="Role Name"
              name="rolename"
              rules={[
                { required: true, message: "Please input your RoleName!" },
              ]}
            >
              <Input placeholder="Role Name" />
            </Form.Item>

            <TreeControl {...table_props}></TreeControl>

            <Row justify="center">
              <Col>
                <Button onClick={onCancelClick} style={{ marginRight: 25 }}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  {id ? "Edit Role" : "Create Role"}
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
}

AddOrEditRole.defaultProps = {
  id: null,
  rolename: null,
  permissions: [],
  cancelAddOrEdit: () => {},
};

AddOrEditRole.propTypes = {
  id: PropTypes.string,
  rolename: PropTypes.string,
  permissions: PropTypes.array,
  cancelAddOrEdit: PropTypes.func,
};

export default AddOrEditRole;
