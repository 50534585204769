import React from "react";
import { Link } from "react-scroll";
import { Col, Row } from "antd";

const Documents = () => {
  return (
    <>
      <section className="how-it-works-section" id="how-it-works">
        <div className="container">
          <div className="bg-box">
            <Row gutter={16} align="bottom">
              <Col span={12}>
                <div className="text-center pl-50">
                  <img
                    src="images/how-it-works-2.png"
                    className="document-pet"
                  />
                </div>
              </Col>
              <Col span={12}>
                <p className="text-primary mb-0">
                  <b>Few Easy Steps to Pet Insurance:</b>
                </p>
                <h2 className="h2-1">Pet Insurance</h2>
                <ul className="ul_style">
                  <li>
                    Enter the proposal details in our chat/register your enquiry
                    with our sales team
                  </li>
                  <li>Attach required documents/photos/videos of your pet</li>
                  <li>Pay Premium online</li>
                  <li>
                    {" "}
                    <Link offset={-125} spy to="faq">
                      {" "}
                      FAQs on Proposal/documentation{" "}
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* eligible */}
    </>
  );
};

export default Documents;
