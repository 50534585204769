import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SiteConfig from "./SiteConfig";
import { Row, Col, Form, Input, Button } from 'antd';


const Footer = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  return (

<>
  <section className="contact-section" id="contact-us">
    <div className="container">
      <div className="text-center mb-5">
        <h2 className="h2-1">Start protecting your pet today!</h2>
        <p>
          Still have queries? Just send us the message. We will get back to you
          soon.
        </p>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <div className="contact-lists">
            <h4>Call us</h4>
            <p>Our Sales team will be happy to help!</p>
            <p className="ans">{SiteConfig.call}</p>
          </div>
          <div className="contact-lists">
            <h4>Chat with us</h4>
            <p>Write to our sales team directly</p>
            <p className="ans">{SiteConfig.support}</p>
          </div>
          <div className="contact-lists">
            <h4>Follow us</h4>
            <div className="social-links">
            <a href={SiteConfig.facebook} target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
            <a href={SiteConfig.twitter} target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
            <a href={SiteConfig.linkedIn} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
            <a href={SiteConfig.instagram} target="_blank"><i className="fa-brands fa-instagram"></i></a>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="contact-flex">



 <Form layout="vertical">
              <Form.Item                
                name="name"
                rules={[{ required: true, message: "Please input App Name" }]}
              >
                <Input prefix={<i className="mdi mdi-account-outline" />} placeholder="Name" />
              </Form.Item>

              <Form.Item                
                name="email"
                rules={[{ required: true, message: "Please input App Name" }]}
              >
                <Input prefix={<i className="mdi mdi-email-outline" />} placeholder="Email id" />
              </Form.Item>

              <Form.Item                
                name="phone"
                rules={[{ required: true, message: "Please input App Name" }]}
              >
                <Input prefix={<i className="mdi mdi-phone-in-talk-outline" />} placeholder="Phone Number" />
              </Form.Item>
              

              <Form.Item                
                name="message"
                rules={[{ required: true, message: "Please input App Name" }]}
              >
                <Input.TextArea placeholder="Message" />
              </Form.Item>

                <Button block type="primary" htmlType="submit">
                  Submit
                </Button>
              
            </Form>
        </div>
        </Col>
      </Row>
    </div>
  </section>
  {/* contact us */}
  {/* footer */}
  <section className="site_footer">
    <div className="container">
      
      <div className="text-center">
        <div className="img">
          <img alt="footer pet" width="1800" height="309" src="images/footer-pet.jpg" />
        </div>
        <p>Copyright © 2023 Petzadore. All Rights Reserved.</p>
        <ul className="footer-links">
          <li>
            <Link to="/terms-and-condition">Terms and Condition</Link>
          </li>
          <li>
            <a href="">Privacy Policy</a>
          </li>
          <li>
            <a href="">Disclaimer</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</>

  );
};

export default Footer;
