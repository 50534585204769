import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Tag,
  Space,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message
} from "antd";
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Axios from '../Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function PriceMatrixForm() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [enableSubmit, setEnableSubmit] = useState(false);

  useState(() => {
    if (id) {
      axios.get('pricematrix/' + id).then((response) => {
        console.log(response);
        if (response?.data?.priceMatrix) {
          form.setFieldsValue({
            ...response.data.priceMatrix
          });
        }
      }).catch(err => {
        message.error(`Unable to get the PriceMatrix details`);
        console.error(err);
      });
      setEnableSubmit(isEnabledByRole('pricematrixmanagement-updatematrix'))
    } else {
      setEnableSubmit(isEnabledByRole('pricematrixmanagement-creatematrix'))
    }
  }, []);

  const onFinish = (values) => {
    if (id) {
      axios.put('pricematrix/' + id, values).then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          setTimeout(() => {
            navigate(-1);
          }, 2000);

        }
      }).catch(err => {
        message.error(`Unable to save the PriceMatrix details.`);
        console.error(err);
      });
    }
    else {
      axios.post('pricematrix', values).then((response) => {
        if (response) {
          message.success('Successfully saved');
          setTimeout(() => {
            navigate(-1)
          }, 2000);
        }
      }).catch(err => {
        message.error(`Unable to save`);
        console.error(err);
      });
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <Card title={id ? "Edit" : "Add New"}>
            <Form layout="vertical" form={form} onFinish={onFinish}
              onFinishFailed={onFinishFailed} autoComplete="off">
              <Form.Item
                label="Insurance Name"
                name="insuranceName"
                rules={[{ required: true, message: "Please input Insurance Name" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Pet Type" name="petType">
                <Select>
                  <Select.Option value="1">Cat</Select.Option>
                  <Select.Option value="2">Dog</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Breed" name="petBreed">
                <Select>
                  <Select.Option value="breed_1">Breed 1</Select.Option>
                  <Select.Option value="breed_2">Breed 2</Select.Option>
                  <Select.Option value="breed_3">Breed 3</Select.Option>
                </Select>
              </Form.Item>


              <Form.Item
                label="Pet Age"
                name="petAge"
                rules={[{ required: true, message: "Please input Pet age" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Pet Description"
                name="petDescription"
                rules={[{ required: true, message: "Please input description" }]}
              >
                <Input />
              </Form.Item>


              <Form.Item label="Pet Category" name="petCategory">
                <Select>
                  <Select.Option value="category_1">Category 1</Select.Option>
                  <Select.Option value="category_2">Category 2</Select.Option>
                  <Select.Option value="category_3">Category 3</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="PED price"
                name="maxPedBreedSi"
                rules={[{ required: true, message: "Please input PED price" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="NOR price"
                name="maxNorBreedSi"
                rules={[{ required: true, message: "Please input NOR price" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Status" name="status">
                <Select>
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>In Active</Select.Option>
                </Select>
              </Form.Item>

              <Flex
                align="center"
                justify="center"
                gap="middle"
                style={{ marginTop: 25 }}
              >
                <Button onClick={() => navigate(-1)}>Back</Button>
                {enableSubmit &&
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                }
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PriceMatrixForm;
