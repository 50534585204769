import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Tag,
  Space,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Axios from '../Common/interceptor';
import { useParams } from 'react-router';
import { isEnabledByRole } from '../Common/roleAnalyser';

function QuoteForm() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [enableSubmit, setEnableSubmit] = useState(false);

  useState(() => {
    if (id) {
      axios.get('quote/' + id).then((response) => {
        if (response?.data?.quote) {
          form.setFieldsValue({
            ...response.data.quote
          });
        }
      }).catch(err => {
        message.error(`Unable to get the Quote details`);
        console.error(err);
      });
      setEnableSubmit(isEnabledByRole('quotemanagement-updatequote'))
    } else {
      setEnableSubmit(isEnabledByRole('quotemanagement-createquote'))
    }
  }, []);

  const onFinish = (values) => {
    if (id) {
      axios.put('quote/' + id, values).then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          setTimeout(() => {
            navigate(-1);
          }, 2000);

        }
      }).catch(err => {
        message.error(`Unable to save the Quote details.`);
        console.error(err);
      });
    }
    else {
      axios.post('quote', values).then((response) => {
        if (response) {
          message.success('Successfully saved');
          setTimeout(() => {
            navigate(-1)
          }, 2000);
        }
      }).catch(err => {
        message.error(`Unable to save`);
        console.error(err);
      });
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };


  return (
    <>
      <Card title={id ? "Edit" : "Add New"}>
        <Form layout="vertical" form={form} onFinish={onFinish}
          onFinishFailed={onFinishFailed} autoComplete="off">
          <Row gutter={16}>
            <Col span="8">
              <Form.Item
                label="Policy ID"
                name="policyID"
                rules={[{ required: true, message: "Please input PolicyId" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  { required: true, message: "Please input UserName" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="User Email"
                name="useremailId"
                rules={[{ required: true, message: "Please input email" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <h3 style={{ marginTop: 0 }}>Pet Information</h3>

          <Row gutter={16}>
            <Col span="8">
              <Form.Item
                label="Pet Name"
                name="petName"
                rules={[{ required: true, message: "Please input Pet Name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Pet Plan" name="petPlan">
                <Select>
                  <Select.Option value="Standard">Standard</Select.Option>
                  <Select.Option value="Premium">Premium</Select.Option>
                  <Select.Option value="SuperPremium">SuperPremium</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="petBreed" name="petBreed">
                <Select>
                  <Select.Option value="Breed_1">Breed_1</Select.Option>
                  <Select.Option value="Breed_2">Breed_2</Select.Option>
                  <Select.Option value="Breed_3">Breed_3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Pet Sex" name="petSex">
                <Select>
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value="Female">Female</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Pet Age"
                name="petAge"
                rules={[{ required: true, message: "Please input Pet age" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item
                label="Micro chip number"
                name="microchipNumber"
                rules={[{ required: true, message: "Please input Microchip number" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span="8">
              <Form.Item label="Pet Category" name="petCategory">
                <Select>
                  <Select.Option value="category_1">Category 1</Select.Option>
                  <Select.Option value="category_2">Category 2</Select.Option>
                  <Select.Option value="category_3">Category 3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <h3 style={{ marginTop: 0 }}>Billing Information</h3>

          <Row gutter={16}>
            <Col span="8">
              <Form.Item
                label="Net Premium"
                name="netPremium"
                rules={[{ required: true, message: "Please input Net Premium" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="SGST"
                name="sgst"
                rules={[{ required: true, message: "Please input App email" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="CGST"
                name="cgst"
                rules={[{ required: true, message: "Please input App email" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Final Premium"
                name="finalPremium"
                rules={[{ required: true, message: "Please input App email" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="Payment Status" name="paymentStatus">
                <Select>
                  <Select.Option value="1">Pending</Select.Option>
                  <Select.Option value="2">Success</Select.Option>
                  <Select.Option value="3">Failure</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Flex
            align="center"
            justify="center"
            gap="middle"
            style={{ marginTop: 25 }}
          >
            <Button onClick={() => navigate(-1)}>Back</Button>
            {enableSubmit &&
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            }
          </Flex>
        </Form>
      </Card>
    </>
  );
}

export default QuoteForm;
