import React from "react";
import { Col, Row } from "antd";

const WhyOurPetInsurance = () => {
  return (
    <>
      {/* home banner */}
      <section className="feature-box why-our">
        <div className="container">
          <h2 className="h2-1 text-center">Why Our Pet Insurance?</h2>
          <p className="text-center">
            Count on our exceptional sevice for your peace of mind.
          </p>
          <div className="text-center">
            <div className="customer-review">
              <div className="rateyo">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-regular fa-star-half-stroke"></i>
              </div>
              <p>
                Our customers say <b>Excellent</b> 4.5 out of 5 based on 75
                reviews
              </p>
            </div>
          </div>
          <Row gutter={16}>
            <Col span={6}>
              <div className="box">
                <img src="images/icon4.png" />
                <p>Easy Processing</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="box">
                <img src="images/icon3.png" />
                <p>Comprehensive coverage</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="box">
                <img src="images/icon5.png" />
                <p>Attractive Discounts</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="box">
                <img src="images/icon1.png" />
                <p>Quick claims</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* home banner */}
    </>
  );
};

export default WhyOurPetInsurance;
