import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Tag, Space, Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import View from "./enquiryView";
import Axios from "../Common/interceptor";
import { isEnabledByRole } from "../Common/roleAnalyser";
import Dropdown from "antd/es/dropdown/dropdown";
import { Menu } from "antd";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

function Enquiry() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [matrixes, setMatrixes] = useState(null);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [view, setView] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [filteredMatrixes, setFilteredMatrixes] = useState(null);

  const showDrawer = (data) => {
    setOpen(true);
    axios
      .get("enquiry/" + data.key)
      .then((response) => {
        if (response?.data?.enquiry) {
          setView(response.data.enquiry);
        }
      })
      .catch((err) => {
        message.error(`Unable to get matrix details.`);
        console.error(err);
      });
  };

  const onClose = () => {
    setOpen(false);
  };

  const confirm = (id) => {
    axios
      .delete("enquiry/" + id)
      .then(() => {
        message.success(`Successfully deleted`);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((err) => {
        message.error(`Delete failed`);
        console.error(err);
      });
  };

  const cancel = () => {
    message.info(`Delete request cancelled`);
  };

  const handleFollowUpClick = (record) => {
    record.status = 2;
    axios
      .put("enquiry/" + record.key, record)
      .then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      })
      .catch((err) => {
        message.error(`Unable to save the Enquiry details.`);
        console.error(err);
      });
  };

  const handleRangePickerChange = (dates) => {
    setDateRange(dates);
  };

  const handleCustomerNotInterestedClick = (record) => {
    record.status = 3;
    axios
      .put("enquiry/" + record.key, record)
      .then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        }
      })
      .catch((err) => {
        message.error(`Unable to save the Enquiry details.`);
        console.error(err);
      });
  };

  let columns = [
    {
      title: "Enquiry Number",
      dataIndex: "enquiryNumber",
      key: "enquiryNumber",
    },
    {
      title: "Date of enquiry",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Owner's Age",
      dataIndex: "ownerAge",
      key: "ownerAge",
    },
    {
      title: "Owner’s Contact Mobile No.",
      dataIndex: "ownerCantactNo",
      key: "ownerCantactNo",
    },
    {
      title: "Pin Code",
      dataIndex: "pinCode",
      key: "pinCode",
    },
    {
      title: "Pet Type",
      dataIndex: "petType",
      key: "petType",
    },
    {
      title: "Pet Name",
      dataIndex: "petName",
      key: "petName",
    },
    {
      title: "Referrer Code",
      dataIndex: "referrerCode",
      key: "referrerCode",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status === 1 ? "green" : status === 2 ? "yellow" : "red"}>
            {status === 1
              ? "New Enquiry"
              : status === 2
              ? "Follow Up"
              : "Customer Not Interested"}
          </Tag>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => handleFollowUpClick(record)}>
                  Follow Up
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => handleCustomerNotInterestedClick(record)}
                >
                  Customer Not Interested
                </Menu.Item>
              </Menu>
            }
          >
            {/* <a onClick={(e) => e.preventDefault()}>Follow Up</a> */}
            <Button type="primary">Follow Up</Button>
          </Dropdown>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => confirm(record.key)}
            onCancel={() => cancel()}
            okText="Yes"
            cancelText="No"
          >
            {isEnabledByRole("enquiry-deleteenquiry") && (
              <Button type="primary" danger>
                Delete
              </Button>
            )}
          </Popconfirm>

          {isEnabledByRole("enquiry-updateenquiry") && (
            <Button
              type="primary"
              onClick={() => navigate("/enquiry/" + record.key)}
            >
              Edit
            </Button>
          )}
          {isEnabledByRole("enquiry-readenquiry") && (
            <Button
              type="primary"
              className="view_button"
              onClick={() => showDrawer(record)}
            >
              View
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get("enquiry")
      .then((response) => {
        if (response?.data?.allEnquirys) {
          const data = response?.data?.allEnquirys.map((x) => {
            return {
              key: x._id,
              ownerName: x.ownerName,
              ownerCantactNo: x.ownerCantactNo,
              petName: x.petName,
              petAge: x.petAge,
              petGender: x.petGender,
              petBreed: x.petBreed,
              registeredOrUnregistered:
                x.registeredOrUnregistered === 1
                  ? "Registered"
                  : "Unregistered",
              vaccinatedOrNot: x.vaccinatedOrNot,
              status: x.status,
              enquiryDate: x.enquiryDate,
              ownerAge: x.ownerAge,
              pinCode: x.pinCode,
              petType: x.petType === 1 ? "Cat" : "Dog",
              referrerCode: x.referrerCode,
              enquiryNumber: x.enquiryNumber,
            };
          });

          const filteredData =
            dateRange?.length === 2
              ? data.filter((item) => {
                  const formatedDate = moment(item.enquiryDate).format(
                    "YYYY-MM-DD"
                  );
                  return (
                    moment(formatedDate).isSameOrAfter(
                      dateRange[0].format("YYYY-MM-DD")
                    ) &&
                    moment(formatedDate).isSameOrBefore(
                      dateRange[1].format("YYYY-MM-DD")
                    )
                  );
                })
              : data;
          setMatrixes(data);
          setFilteredMatrixes(filteredData);
        }
      })
      .catch((err) => {
        message.error(`Unable to get Enquiries`);
        console.error(err);
      });
  }, [dateRange]);

  const handleDownload = async (downloadType) => {
    try {
      if (filteredMatrixes.length > 0) {
        const url =
          dateRange.length > 0
            ? `${
                process.env.REACT_APP_NODE_ENDPOINT
              }/enquiry?download_type=${downloadType}&from_date=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1].format("YYYY-MM-DD")}`
            : `${process.env.REACT_APP_NODE_ENDPOINT}/enquiry?download_type=${downloadType}`;

        const response = await axios.get(url);

        if (response.status !== 200) {
          throw new Error(
            `Failed to download file. Status: ${response.status}`
          );
        }

        const downloadLink = response.data.downloadLink;
        if (downloadType === 2) {
          window.open(downloadLink, "_blank");
        } else {
          const downloadAnchor = document.createElement("a");
          downloadAnchor.href = downloadLink;
          const filename = downloadLink
            ? downloadLink.substring(downloadLink.lastIndexOf("/") + 1)
            : "downloaded_file";
          downloadAnchor.download = filename;
          downloadAnchor.style.display = "none";

          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <div>
          <h2 style={{ margin: 0, marginRight: 25 }}>Enquiry</h2>
        </div>
        <RangePicker
          size="middle"
          style={{ marginRight: "auto" }}
          onChange={handleRangePickerChange}
        />

        <div>
          <Button
            disabled={filteredMatrixes?.length === 0}
            onClick={() => handleDownload(2)}
            type="default"
          >
            Download as PDF
          </Button>
          <Button
            disabled={filteredMatrixes?.length === 0}
            onClick={() => handleDownload(1)}
            type="default"
          >
            Download as Excel
          </Button>
        </div>
        <div>
          {isEnabledByRole("enquiry-createenquiry") && (
            <Button onClick={() => navigate("/enquiry/add")} type="primary">
              Add New
            </Button>
          )}
        </div>
      </Flex>

      {isEnabledByRole("enquiry-readenquiry") && (
        <Table columns={columns} dataSource={filteredMatrixes} />
      )}
      <View onClose={onClose} open={open} data={view} />
    </>
  );
}

export default Enquiry;
