import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

const WhatIsCovered = () => {
  return (
    <>
      <div id="whats-covered" name="whats-covered">
        {/* feature box */}
        <section className="feature-box">
          <div className="container">
            <h2 className="h2-1 text-center mb-5">What is covered</h2>
            <div className="about-icon-flex">
              <div className="icons">
                {" "}
                <img
                  width="70"
                  height="70"
                  alt="icon"
                  src="images/icon1.png"
                />{" "}
                <p>Hospitalisation Expenses</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon2.png" />{" "}
                <p>Mortality Benefit</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon3.png" />{" "}
                <p>Terminal Diseases</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon4.png" />{" "}
                <p>Long Term Care Cover</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon5.png" />{" "}
                <p>OPD Cover</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon6.png" />{" "}
                <p>Third Party Liability Cover</p>
              </div>
              <div className="icons">
                <img width="70" height="70" alt="icon" src="images/icon7.png" />{" "}
                <p>Theft/Lost/Straying Cover</p>
              </div>
            </div>
          </div>
        </section>
        {/* feature box */}
        {/* eligible */}
        <section className="eligible-breed">
          <div className="container">
            <div className="box">
              <Row gutter={16}>
                <Col span={12}>
                  <p className="mb-0 text-primary">
                    <b>Breeds Covered</b>
                  </p>
                  <h2 className="h2-1">Eligibility Criteria</h2>
                  <p>
                    Pets of Indigenous Origin, Cross-bred and Exotic breeds are
                    covered under the Policy.{" "}
                  </p>
                  <img
                    width="1033"
                    height="659"
                    alt="faq"
                    src="images/faq.png"
                    className="big-img"
                  />
                </Col>
                <Col span={12}>
                  <div className="table-overlay">
                    <table className="table-ui">
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <b>Breed Type</b>
                          </td>
                          <td>
                            <b>Entry Age</b>
                          </td>
                          <td>
                            <b>Exit Age</b>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>
                            <img
                              width="200"
                              height="201"
                              alt="dog"
                              src="images/dog.png"
                            />
                          </td>
                          <td>Small | Medium | Large</td>
                          <td>3 Months - 7 Years</td>
                          <td>10 Years</td>
                        </tr>
                        <tr>
                          <td>Giant</td>
                          <td>3 Months - 4 Years</td>
                          <td>6 Years</td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              width="200"
                              height="201"
                              alt="cat"
                              src="images/cat.png"
                            />
                          </td>
                          <td>All Breeds</td>
                          <td>3 Months - 7 Years</td>
                          <td>12 Years</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="center-small-policy">
                    <p>
                      • This policy can be issued only to domestic &amp;
                      commercial use pets (not to pets used for breeding
                      purpose).
                    </p>
                    <p>
                      • Pet needs to be vaccinated at the time of buying the
                      policy and throughout the policy period.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
      {/* eligible */}
    </>
  );
};

export default WhatIsCovered;
