import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "antd";


const NotFound = () => {
   const navigate = useNavigate()
  return (
    <>
      <section className="notfound">
        <div className="container">
              <div className="text-center">
                <img alt={"Looks Like You're Lost"} src="/images/footer-pet.jpg" />
                <h1 className="mb-0">Looks Like You're Lost</h1>
                <p className="text-secondary my-4">The page you are looking for not availble!</p>
                <Button onClick={() => navigate(-1)} className="btn btn-primary">Back to Home</Button>
              </div>
        </div>
      </section>
    </>
  );
};
export default NotFound;
