import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';


const createTree = (treeData) => {
    const treeKeys = Object.keys(treeData);
    const treeStructure = [];
    treeKeys.forEach(node => {
        const child = {};
        child.title = <b>{node}</b>;
        const { key, permissions } = treeData[node]
        child.key = key;
        child.children = permissions.map(x => {
            return { title: x.name, key: key + "-" + x.key }
        });
        treeStructure.push(child);
    })
    return treeStructure;
};

function TreeControl(props) {
    const { treeData, onSelectChanged, onCheckedChanged, defaultCheckedKeys } = props;

    const treeData1 = createTree(treeData);

    const onSelect = (selectedKeys, info) => {
        onSelectChanged(selectedKeys, info);
    };

    const onCheck = (checkedKeys, info) => {
        onCheckedChanged(checkedKeys, info);
    };

    return (
        <Tree
            checkable
            defaultExpandedKeys={defaultCheckedKeys}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            defaultCheckedKeys={defaultCheckedKeys}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData1}
        />
    );
}

TreeControl.defaultProps = {
    treeData: null,
    defaultCheckedKeys: null
};

TreeControl.propTypes = {
    treeData: PropTypes.object,
    onSelectChanged: PropTypes.func,
    onCheckedChanged: PropTypes.func,
    defaultCheckedKeys: PropTypes.array
};

export default TreeControl