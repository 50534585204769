import React, { useState } from 'react';
import { Tabs } from 'antd';
import Roles from './roles/Roles';
import Users from './users/Users';

function Management() {
    const [tab, setTab] = useState(localStorage.getItem('usertab') ?? 1);
    const onChange = (key) => {
        setTab(key);
        localStorage.setItem('usertab', key);
    };

    const items = [
        {
            key: '1',
            label: 'Roles Management',
            children: <Roles />,
        },
        {
            key: '2',
            label: 'Users Management',
            children: <Users />,
        },
    ];

    return (
        <Tabs defaultActiveKey={tab} centered items={items} onChange={onChange} />
    )
}

export default Management