// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-end;
  text-align: right;
}

.option-button {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 5px 15px;
  border-radius: 35px;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  border: 1px solid #333;
  font-weight: 600;
}

.option-button:hover {
  background-color: #333;
  color: #fff;
}

.info-label {
  font-size: 13px;
  margin-bottom: 0;
  width: 100%;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/chatbot/CustomOptions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".options-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 5px;\n  justify-content: flex-end;\n  text-align: right;\n}\n\n.option-button {\n  background-color: #fff;\n  color: #333;\n  border: none;\n  padding: 5px 15px;\n  border-radius: 35px;\n  cursor: pointer;\n  display: inline-block;\n  font-size: inherit;\n  border: 1px solid #333;\n  font-weight: 600;\n}\n\n.option-button:hover {\n  background-color: #333;\n  color: #fff;\n}\n\n.info-label {\n  font-size: 13px;\n  margin-bottom: 0;\n  width: 100%;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
