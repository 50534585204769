import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { isEnabledByRole } from '../Common/roleAnalyser';

const InsurerView = ({ onClose, open, data }) => {
  if (isEnabledByRole('pricematrixmanagement-readmatrix')) {
    return (
      <Drawer title="View Detail" placement="right" onClose={onClose} open={open}>
        <div className="view_form">
          <label>Insurance Name</label>
          <p>{data?.insuranceName}</p>
        </div>

        <div className="view_form">
          <label>Pet Type</label>
          <p>{data?.petType == "1" ? "Cat" : "Dog"}</p>
        </div>

        <div className="view_form">
          <label>Pet Breed</label>
          <p>{data?.petBreed}</p>
        </div>

        <div className="view_form">
          <label>Pet Age</label>
          <p>{data?.petAge}</p>
        </div>

        <div className="view_form">
          <label>Ped Price</label>
          <p>{data?.maxPedBreedSi}</p>
        </div>

        <div className="view_form">
          <label>Nor Price</label>
          <p>{data?.maxNorBreedSi}</p>
        </div>

        <div className="view_form">
          <label>Status</label>
          <p>{data?.status ? "Active" : "InActive"}</p>
        </div>
      </Drawer>
    );
  }
  return null;
};

export default InsurerView;
