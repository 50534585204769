import React from "react";
import { Link } from 'react-router-dom';

const TermsAndCondition = () => {
  return (
    <>
      <section className="cms-page-section">
        <div className="container">
          <Link to="/" className="btn-back"><i className="fa-solid fa-arrow-left"></i> Back to Home</Link>
          <h2 className="h2-1">Terms and Condition</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eleifend ligula vel fringilla euismod. Integer convallis nec tellus at imperdiet. Integer finibus tristique ex, sit amet convallis augue blandit ut. Morbi at magna vestibulum, condimentum felis eget, maximus eros. Mauris rhoncus scelerisque nunc, quis posuere augue dapibus vitae. Nullam quis nisi eget nibh vulputate venenatis eget et felis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis blandit non sem vel aliquet. Cras porta lobortis massa, nec vestibulum nunc molestie quis. Phasellus pharetra, tellus vitae scelerisque imperdiet, sem orci consectetur nulla, egestas ultrices massa orci quis nunc. Suspendisse eget facilisis ante. Suspendisse mattis massa ac egestas egestas.</p>
          <p>Ut tristique rutrum viverra. Sed at iaculis nisi. Morbi ac laoreet lorem. Cras sit amet finibus urna. Donec eget elit vel metus semper dignissim. Etiam scelerisque ex vitae risus sodales, quis mollis quam consectetur. Praesent nec facilisis ipsum. Ut sagittis elit fermentum lorem ullamcorper, eget egestas magna condimentum. Duis blandit quam at lacus pulvinar, et aliquet justo sagittis. Aenean luctus elit et odio imperdiet malesuada. Curabitur suscipit finibus enim in gravida.</p>
          <p>Aenean dapibus nunc purus, ac pharetra ipsum volutpat eget. Maecenas pulvinar ex quis dui auctor, vitae condimentum diam pellentesque. Curabitur luctus porttitor elementum. Sed porttitor, ex nec tincidunt tempus, sem nunc tempus nulla, eu pellentesque justo elit non leo. Mauris in nunc vitae erat tempor aliquam a vitae est. Quisque euismod magna quis porttitor sollicitudin. Praesent vel porttitor velit. Quisque ac tincidunt metus. Proin pretium nisi mauris, vel suscipit leo vestibulum nec. Aenean venenatis elit ac purus hendrerit, at sagittis augue varius. Sed interdum diam ipsum, vitae condimentum orci gravida vitae. Quisque sodales ornare pellentesque. Integer purus tortor, ultrices a dolor et, pellentesque tristique purus.</p>
          <p>Morbi rhoncus a ligula quis lacinia. Aliquam erat volutpat. Nam posuere molestie diam, ut consectetur justo pulvinar non. Vestibulum vel erat nec purus iaculis egestas. Aenean eu arcu lacus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris fringilla sapien id tellus pharetra condimentum. Nulla faucibus bibendum commodo.</p>
          <p>
          Nulla id ornare nibh. Duis viverra placerat mi at eleifend. Nunc sagittis, ex eu tempor dapibus, ante metus venenatis lacus, nec blandit enim ante nec nibh. Nullam ullamcorper arcu dapibus sagittis molestie. Vestibulum vestibulum et felis in dignissim. Sed quis risus sit amet purus dictum pulvinar. Nam non faucibus massa, nec viverra quam. Nullam sit amet diam eu odio iaculis tempor in ut sapien. Pellentesque euismod tellus tortor. Vestibulum hendrerit, mauris nec aliquet finibus, urna dolor pellentesque orci, a egestas purus orci a erat. Quisque ante justo, fringilla vitae nulla id, sagittis porttitor quam. In vestibulum risus ut faucibus dapibus. Maecenas sed turpis sed ex varius mollis.
          </p>
        </div>
      </section>
    </>
  );
};
export default TermsAndCondition;
