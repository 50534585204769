

export function isEnabledByRole(key) {
  if (key) {
    if (localStorage.getItem('userType') == "1") { // If superadmin give all access
      return true;
    }

    const permissions = localStorage.getItem('permissions')?.split(',');
    return permissions?.includes(key) ?? false;
  }
  else {
    return localStorage.getItem('userType') == "1";// checking if he is super admin
  }
}