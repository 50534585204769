import React, { useEffect } from "react";
import {
  Flex,
  Button,
  Form,
  Row,
  Col,
  Card,
  Input,
  Select,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";

import Axios from "../../src/Common/interceptor";

function PetStoreForm() {
  const navigate = useNavigate();

  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [form] = Form.useForm();

  useEffect(() => {
    axios
      .get("referrersetting")
      .then((response) => {
        if (response?.data?.referrersetting) {
          form.setFieldsValue({ ...response.data.referrersetting });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function onFinish(values) {
    axios
      .put("referrersetting", values)
      .then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          setTimeout(() => {
            navigate(0);
          }, 2000);
        }
      })
      .catch((err) => {
        message.error(`Unable to update the RefferalSetting details.`);
        console.error(err);
      });
  }

  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <Card title="Referral Settings">
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                label="Store Referral Percentage"
                name="petStoreReferrerPercentage"
                rules={[
                  {
                    required: true,
                    message: "Please input Store Referral Percentage",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Customer Referral Percentage Name"
                name="customerReferrerPercentage"
                rules={[
                  {
                    required: true,
                    message: "Please input Customer Referral Percentage Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Flex
                align="center"
                justify="center"
                gap="middle"
                style={{ marginTop: 25 }}
              >
                {/* <Button onClick={() => navigate(-1)}>Back</Button> */}
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default PetStoreForm;
