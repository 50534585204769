import React, {useState} from "react";
import { Link } from "react-scroll";
import { Link as Linker } from "react-router-dom";
import SiteConfig from "./SiteConfig";


const Header = () => {
   const [checked, setChecked] = useState(false);
    const toggleChecked = () => setChecked(value => !value);
  return (
    <>
      <button role="button" onClick={toggleChecked} className="btn btn-responsive-menu"><i className={checked ? "mdi mdi-close" : "mdi mdi-menu" }></i></button>
      {/* header */}
      <header className={checked ? "site_header responsive-menu-shown" : "site_header" }>
        <div className="container">
        <div className="left-side">
          {/*logo  */}
          <div className="logo">
            <Link to="/">
              <img
                alt={"Petzadore"}
                height="85"
                src={"images/logo.png"}
                width="263"
              />
            </Link>
          </div>
          {/*logo  */}
          <div className="navigations">
            <ul>
              <li><Link activeClass="active" offset={checked ? 0 : -125}  spy to="home">Home </Link></li>
              <li><Link activeClass="active" offset={checked ? 0 : -125} spy to="whats-covered">What is Covered?</Link></li>
              <li><Link activeClass="active" offset={checked ? 0 : -125} spy to="plans">Plans</Link></li>
              <li><Link activeClass="active" offset={checked ? 0 : -125} spy to="download-app">Download App </Link></li>
              <li><Link activeClass="active" offset={checked ? 0 : -125} spy to="faq">FAQ</Link></li>
              <li><Link activeClass="active" offset={checked ? 0 : -125} spy to="contact-us">Contact Us</Link></li>
              <li><Linker to="/partner-with-us">Partner with us</Linker></li>
            </ul>
          </div>
          <div className="social-links">
            <a href={SiteConfig.facebook} target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
            <a href={SiteConfig.twitter} target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
            <a href={SiteConfig.linkedIn} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
            <a href={SiteConfig.instagram} target="_blank"><i className="fa-brands fa-instagram"></i></a>
          </div>
        </div>
        </div>
      </header>
      {/* header */}
    </>
  );
};

export default Header;
