import React from 'react';

const PaymentLinkWidget = ({ payload }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleClick = () => {
    if (isMobile) {
      // Android Chrome intent to open the link in the Chrome app
      const intentUrl = `intent://${payload.url.replace(
        'https://',
        ''
      )}#Intent;package=com.android.chrome;scheme=https;end`;
      window.location.href = intentUrl;
    } else {
      window.open(payload.url, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <div>
      <button className='payment-button' onClick={handleClick}>
        Proceed to Payment
      </button>
    </div>
  );
};

export default PaymentLinkWidget;
