import React, { useEffect, useState } from 'react';
import { Space, Table, Button, message, Switch, Popconfirm, Flex } from 'antd';
import { NavLink, useNavigate } from "react-router-dom";
import Axios from '../../../src/Common/interceptor';
import AddOrEditUser from './AddOrEditUser';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
function Users() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [isCreateOrEdit, setIsCreateOrEdit] = useState(false);
  const [userEdit, setuserEdit] = useState(null);
  const [data, setData] = useState([]);
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Role Name',
      dataIndex: 'rolename',
      key: 'rolename',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const onChange = (id, status) => {
    axios.put('user/' + id, { status }).then((response) => {
      if (response) {
        console.log(response);
        message.success("Successfully updated ")
      }
    }).catch(err => {
      message.error(`Unable to update User.`);
      console.error(err);
    });
  }

  const onUserEditClick = (_id) => {
    setIsCreateOrEdit(true);
    setuserEdit(_id);
  }

  const cancelAddOrEdit = () => {
    setIsCreateOrEdit(false);
    setuserEdit(null)
  }

  const confirm = (userId) => {
    axios.delete('user/' + userId).then(() => {
      message.success(`Successfully deleted`);
      setTimeout(() => {
        navigate(0)
      }, 2000);

    }).catch(err => {
      message.error(`Delete failed`);
      console.error(err)
    });
  };

  const cancel = (rolename) => {
    message.info(`Delete request cancelled for ${rolename}`);
  };

  useEffect(() => {
    axios.get('user').then(async (response) => {
      console.log('response:: ', response);
      if (response?.data?.allusers) {
        console.log(response.data.allusers);

        const fetchRoles = await axios.get('roles').then((response) => {
          if (response?.data?.roles) {
            return response.data.roles.map(x => {
              return { id: x._id, name: x.rolename }
            });
          }
        }).catch(err => {
          message.error(`Unable to load Roles.`);
          console.error(err);
        });

        // setAllRoles(allroles);

        const tabledata = response.data.allusers.map(({ _id,
          createdAt,
          firstName,
          roleId,
          status, }) => {
          return {
            _id, firstName, rolename: roleId ? fetchRoles?.find(x => x?.id === roleId)?.name : "No Role",
            status: <Switch defaultChecked={status ?? false}
              checkedChildren="On" unCheckedChildren="Off" onChange={(value) => onChange(_id, value)} />,
            action:
              <Space>
                <NavLink
                  onClick={() => onUserEditClick(_id)}
                >
                  <AiOutlineEdit size={25} />
                </NavLink>


                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  onConfirm={() => confirm(_id)}
                  onCancel={() => cancel()}
                  okText="Yes"
                  cancelText="No"
                >
                  <NavLink> <AiOutlineDelete size={25} /> </NavLink>
                </Popconfirm>
              </Space>


          };
        })
        setData(tabledata);
      }
    }).catch(err => {
      message.error(`Unable to load Users.`);
      console.error(err);
    });

  }, [])

  if (!isCreateOrEdit) {
    return (
      <>

        <Flex
          align="center"
          justify="space-between"
          style={{ marginBottom: 35, marginTop: 25 }}
        >
          <h2 style={{ margin: 0 }}>User Management</h2>
          <div>
            <Button type="primary" onClick={() => {
              setIsCreateOrEdit(true);
              setuserEdit(null);
            }}>
              Create User
            </Button>
          </div>
        </Flex>

        <Table dataSource={data} columns={columns} />
      </>
    )
  }
  else {
    let props = {};
    if (userEdit) {
      props = { id: userEdit }
    }
    props = { ...props, cancelAddOrEdit };
    return (<AddOrEditUser {...props}></AddOrEditUser>);
  }
}

export default Users