import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Space, Tag, message, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import Axios from "../Common/interceptor";
import View from "./partnersView";
import { DatePicker } from "antd";
import moment from "moment";
import Dropdown from "antd/es/dropdown/dropdown";
import { Menu } from "antd";

const { RangePicker } = DatePicker;

function Partners() {
  const navigate = useNavigate();

  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [open, setOpen] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partnersDetails, setPartnersDetails] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState(null);

  useEffect(() => {
    axios
      .get("partner")
      .then((response) => {
        if (response?.data?.allPartners.length > 0) {
          const partnersList = response.data.allPartners.map((partner) => {
            return {
              ...partner,
              key: partner._id,
            };
          });
          setPartners(partnersList);
          setFilteredPartners(partnersList);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (dateRange?.length === 2) {
      const filtered = partners.filter((partner) => {
        const createdAt = moment(partner.createdAt);
        return createdAt.isBetween(
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
          null,
          "[]"
        );
      });
      setFilteredPartners(filtered);
    } else {
      setFilteredPartners(partners);
    }
  }, [dateRange]);

  const showDrawer = (record) => {
    setOpen(true);
    axios
      .get(`partner/${record._id}`)
      .then((response) => {
        if (response?.data?.partner) {
          setPartnersDetails(response.data.partner[0]);
        }
      })
      .catch((err) => {
        message.error("Unable to get Partners details.");
        console.error(err);
      });
  };
  const onClose = () => {
    setOpen(false);
  };

  const deletePartner = (id) => {
    console.log(id);
    axios
      .delete(`partner/${id}`)
      .then(() => {
        message.success(`Partner successfully deleted`);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((err) => {
        message.error(`Partner delete failed`);
        console.error(err);
      });
  };

  const cancelConfirmationPopup = () => {
    message.info(`Partner delete request cancelled`);
  };

  const handleApproveClick = (record) => {
    record.status = 2;
    axios
      .put("partner/" + record.key, record)
      .then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          const updatedPartners = partners.map((partner) =>
            partner.key === record.key ? { ...partner, status: 1 } : partner
          );
          setPartners(updatedPartners);
        }
      })
      .catch((err) => {
        message.error(`Unable to update details.`);
        console.error(err);
      });
  };

  const handleRejectClick = (record) => {
    record.status = 3;
    axios
      .put("partner/" + record.key, record)
      .then((response) => {
        if (response) {
          message.success(`Updated successfully`);
          const updatedPartners = partners.map((partner) =>
            partner.key === record.key ? { ...partner, status: 0 } : partner
          );
          setPartners(updatedPartners);
        }
      })
      .catch((err) => {
        message.error(`Unable to update details.`);
        console.error(err);
      });
  };

  const columns = [
    {
      title: "Partner Code",
      dataIndex: "partnerCode",
      key: "partnerCode",
    },
    {
      title: "Partner Name",
      dataIndex: "partnerName",
      key: "partnerName",
    },
    {
      title: "Name of the store / establishment",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Email Address",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Pincode",
      dataIndex: "pinCode",
      key: "pinCode",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status === 1 ? "yellow" : status === 2 ? "green" : "red"}>
            {status === 1
              ? "Pending Approval"
              : status === 2
              ? "Approved"
              : "Rejected"}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => handleApproveClick(record)}>
                  Approve
                </Menu.Item>
                <Menu.Item key="2" onClick={() => handleRejectClick(record)}>
                  Reject
                </Menu.Item>
              </Menu>
            }
          >
            {/* <a onClick={(e) => e.preventDefault()}>Follow Up</a> */}
            <Button type="primary">Approval</Button>
          </Dropdown>
          <Popconfirm
            title="Delete Partner"
            description="Are you sure to delete this Partner?"
            onConfirm={() => deletePartner(record._id)}
            onCancel={() => cancelConfirmationPopup()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            onClick={() => navigate(`/partners/edit/${record._id}`)}
          >
            Edit
          </Button>

          <Button
            type="primary"
            className="view_button"
            onClick={() => showDrawer(record)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      firstname: "Pet World",
      lastname: "New",
      ownername: "Mike",
      email: "mike@gmail.com",
      mobile: "9791529316",
      status: 1,
    },
    {
      key: "2",
      firstname: "Pet World",
      lastname: "New",
      ownername: "Mike",
      email: "mike@gmail.com",
      mobile: "9791529316",
      status: 0,
    },
    {
      key: "3",
      firstname: "Pet World",
      lastname: "New",
      ownername: "Mike",
      email: "mike@gmail.com",
      mobile: "9791529316",
      status: 1,
    },
    {
      key: "4",
      firstname: "Pet World",
      lastname: "New",
      ownername: "Mike",
      email: "mike@gmail.com",
      mobile: "9791529316",
      status: 0,
    },
  ];
  const handleDownload = async (downloadType) => {
    try {
      if (filteredPartners.length > 0) {
        const url =
          dateRange.length > 0
            ? `${
                process.env.REACT_APP_NODE_ENDPOINT
              }/partner?download_type=${downloadType}&from_date=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1].format("YYYY-MM-DD")}`
            : `${process.env.REACT_APP_NODE_ENDPOINT}/partner?download_type=${downloadType}`;

        const response = await axios.get(url);

        if (response.status !== 200) {
          throw new Error(
            `Failed to download file. Status: ${response.status}`
          );
        }

        const downloadLink = response.data.downloadLink;
        if (downloadType === 2) {
          window.open(downloadLink, "_blank");
        } else {
          const downloadAnchor = document.createElement("a");
          downloadAnchor.href = downloadLink;
          const filename = downloadLink
            ? downloadLink.substring(downloadLink.lastIndexOf("/") + 1)
            : "downloaded_file";
          downloadAnchor.download = filename;
          downloadAnchor.style.display = "none";

          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  const handleRangePickerChange = (dates) => {
    setDateRange(dates);
  };

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0, marginRight: 25 }}>Partner</h2>
        <RangePicker
          size="middle"
          style={{ marginRight: "auto" }}
          onChange={handleRangePickerChange}
        />

        <div>
          <Button
            disabled={filteredPartners?.length === 0}
            onClick={() => handleDownload(2)}
            type="default"
          >
            Download as PDF
          </Button>
          <Button
            disabled={filteredPartners?.length === 0}
            onClick={() => handleDownload(1)}
            type="default"
          >
            Download as Excel
          </Button>
        </div>
        <Button type="primary" onClick={() => navigate("/partners/add")}>
          Add New
        </Button>
      </Flex>
      <Table columns={columns} dataSource={filteredPartners} />
      <View onClose={onClose} open={open} data={partnersDetails} />
    </>
  );
}
export default Partners;
