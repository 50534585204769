import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Space, Tag, message } from "antd";
import Axios from "../../src/Common/interceptor";
import { DatePicker } from "antd";
import View from "./proposalView";
import { INR } from "../constants/currency";
import moment from "moment";
const { RangePicker } = DatePicker;

function Proposal() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [open, setOpen] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [proposalDetails, setProposalDetails] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [filteredProposals, setFilteredProposals] = useState([]);

  useEffect(() => {
    if (dateRange?.length === 2) {
      const filtered = proposals.filter((proposal) => {
        const createdAt = moment(proposal.createdAt);
        return createdAt.isBetween(
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
          null,
          "[]"
        );
      });
      setFilteredProposals(filtered);
    } else {
      setFilteredProposals(proposals);
    }
  }, [dateRange, proposals]);

  useEffect(() => {
    axios
      .get("proposal")
      .then((response) => {
        if (response?.data?.allProposals.length > 0) {
          const formattedProposals = response.data.allProposals.map(
            (proposal) => {
              const formattedDate = proposal.createdAt
                ? moment(proposal.createdAt).format("YYYY-MM-DD")
                : "";
              return {
                ...proposal,
                totalPremium:
                  proposal.totalPremium && `${INR} ${proposal.totalPremium}`,
                key: proposal._id,
                createdAt: formattedDate,
              };
            }
          );
          setProposals(formattedProposals);
          setFilteredProposals(formattedProposals);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleDownload = async (downloadType) => {
    try {
      if (filteredProposals.length > 0) {
        const url =
          dateRange.length > 0
            ? `${
                process.env.REACT_APP_NODE_ENDPOINT
              }/proposal?download_type=${downloadType}&from_date=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1].format("YYYY-MM-DD")}`
            : `${process.env.REACT_APP_NODE_ENDPOINT}/proposal?download_type=${downloadType}`;

        const response = await axios.get(url);

        if (response.status !== 200) {
          throw new Error(
            `Failed to download file. Status: ${response.status}`
          );
        }

        const downloadLink = response.data.downloadLink;
        if (downloadType === 2) {
          window.open(downloadLink, "_blank");
        } else {
          const downloadAnchor = document.createElement("a");
          downloadAnchor.href = downloadLink;
          const filename = downloadLink
            ? downloadLink.substring(downloadLink.lastIndexOf("/") + 1)
            : "downloaded_file";
          downloadAnchor.download = filename;
          downloadAnchor.style.display = "none";

          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  const showDrawer = (record) => {
    setOpen(true);
    axios
      .get(`proposal/${record._id}`)
      .then((response) => {
        if (response?.data?.proposal.length > 0) {
          setProposalDetails(response.data.proposal[0]);
        }
      })
      .catch((err) => {
        message.error("Unable to get Proposal Details.");
        console.error(err);
      });
  };
  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Enquiry Number",
      dataIndex: "enquiryNumber",
      key: "enquiryNumber",
    },
    {
      title: "Proposal Number",
      dataIndex: "proposalNumber",
      key: "proposalNumber",
    },
    {
      title: "Proposal Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Pet Name",
      dataIndex: "petName",
      key: "petName",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status == "0" ? "red" : "green"}>
            {status == "1" ? "Active" : "In Active"}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            className="view_button"
            onClick={() => showDrawer(record)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      proposalnumber: "554857",
      enquiry: "New",
      ownername: "Mike",
      petname: "Zoya",
      total: "55868",
      status: 1,
    },
    {
      key: "2",
      proposalnumber: "554857",
      enquiry: "New",
      ownername: "Mike",
      petname: "Zoya",
      total: "55868",
      status: 1,
    },
    {
      key: "3",
      proposalnumber: "554857",
      enquiry: "New",
      ownername: "Mike",
      petname: "Zoya",
      total: "55868",
      status: 0,
    },
    {
      key: "4",
      proposalnumber: "554857",
      enquiry: "New",
      ownername: "Mike",
      petname: "Zoya",
      total: "55868",
      status: 0,
    },
  ];

  const handleRangePickerChange = (dates) => {
    setDateRange(dates);
  };
  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0, marginRight: 25 }}>Proposal</h2>
        <RangePicker
          size="middle"
          style={{ marginRight: "auto" }}
          onChange={handleRangePickerChange}
        />
        <div>
          <Button
            disabled={filteredProposals?.length === 0}
            onClick={() => handleDownload(2)}
            type="default"
          >
            Download as PDF
          </Button>
          <Button
            disabled={filteredProposals?.length === 0}
            onClick={() => handleDownload(1)}
            type="default"
          >
            Download as Excel
          </Button>
        </div>
      </Flex>
      <Table columns={columns} dataSource={filteredProposals} />
      <View onClose={onClose} open={open} data={proposalDetails} />
    </>
  );
}
export default Proposal;
