import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Tag, Space, Popconfirm, message } from "antd";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import View from "./priceMatrixView";
import Axios from '../Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function PriceMatrix() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [matrixes, setMatrixes] = useState(null);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [view, setView] = useState(null);

  const showDrawer = (data) => {
    setOpen(true);
    axios.get('pricematrix/' + data.key).then((response) => {
      if (response?.data?.priceMatrix) {
        setView(response.data.priceMatrix);
      }
    }).catch(err => {
      message.error(`Unable to get matrix details.`);
      console.error(err);
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const confirm = (id) => {
    axios.delete('pricematrix/' + id).then(() => {
      message.success(`Successfully deleted`);
      setTimeout(() => {
        navigate(0)
      }, 2000);

    }).catch(err => {
      message.error(`Delete failed`);
      console.error(err)
    });
  };

  const cancel = () => {
    message.info(`Delete request cancelled`);
  };

  const columns = [
    {
      title: "Insurance Name",
      dataIndex: "insuranceName",
      key: "insuranceName",
    },
    {
      title: "Pet Type",
      dataIndex: "petType",
      key: "petType",
    },
    {
      title: "Pet Breed",
      dataIndex: "petBreed",
      key: "petBreed",
    },
    {
      title: "Pet Age",
      dataIndex: "petAge",
      key: "petAge",
    },
    {
      title: "Ped Price",
      dataIndex: "maxPedBreedSi",
      key: "maxPedBreedSi",
    },
    {
      title: "Nor Price",
      dataIndex: "maxNorBreedSi",
      key: "maxPedBreedSi",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag color={status === "In Active" ? "red" : "green"}>{status}</Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => confirm(record.key)}
            onCancel={() => cancel()}
            okText="Yes"
            cancelText="No"
          >
            {isEnabledByRole('pricematrixmanagement-deletematrix') &&
              <Button type="primary" danger>
                Delete
              </Button>
            }
          </Popconfirm>

          {isEnabledByRole('pricematrixmanagement-updatematrix') &&
            <Button type="primary" onClick={() => navigate("/price-matrix/" + record.key)}>
              Edit
            </Button>
          }
          {isEnabledByRole('pricematrixmanagement-readmatrix') &&
            <Button
              type="primary"
              className="view_button"
              onClick={() => showDrawer(record)}
            >
              View
            </Button>
          }
        </Space>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     name: "Insurance 1",
  //     pettype: "Cat",
  //     breed: "Breed 1",
  //     petage: "2 Years",
  //     petprice: "13000",
  //     status: "Active",
  //   },
  //   {
  //     key: "2",
  //     name: "Insurance 2",
  //     pettype: "Dog",
  //     breed: "Breed 2",
  //     petage: "3 Years",
  //     petprice: "3400",
  //     status: "In Active",
  //   },
  //   {
  //     key: "3",
  //     name: "Insurance 3",
  //     pettype: "Dog",
  //     breed: "Breed 2",
  //     petage: "3 Years",
  //     petprice: "3400",
  //     status: "Active",
  //   },
  //   {
  //     key: "4",
  //     name: "Insurance 4",
  //     pettype: "Dog",
  //     breed: "Breed 2",
  //     petage: "3 Years",
  //     petprice: "3400",
  //     status: "Active",
  //   },
  // ];
  useEffect(() => {
    axios.get('pricematrix').then((response) => {
      if (response?.data?.pricematrixs) {
        const data = response?.data?.pricematrixs.map(x => {
          console.log(x);
          return {
            key: x._id,
            insuranceName: x.insuranceName,
            petType: x.petType,
            petBreed: x.petBreed,
            petAge: x.petAge,
            maxPedBreedSi: x.maxPedBreedSi,
            maxNorBreedSi: x.maxNorBreedSi,
            status: x.status
          }
        })
        setMatrixes(data);
      }
    }).catch(err => {
      message.error(`Unable to get Matrices`);
      console.error(err);
    });

  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0 }}>Price Matrix Management</h2>
        <div>
          {isEnabledByRole('pricematrixmanagement-creatematrix') &&
            < Button onClick={() => navigate("/price-matrix/add")} type="primary">
              Add New
            </Button>
          }
        </div>
      </Flex >
      {isEnabledByRole('pricematrixmanagement-readmatrix') &&
        <Table columns={columns} dataSource={matrixes} />
      }
      <View onClose={onClose} open={open} data={view} />
    </>
  );
}

export default PriceMatrix;
