import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, notification, Select, message } from "antd";
import Axios from "../../Common/interceptor";
import { useNavigate } from "react-router-dom";

const PartnerWithUs = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [api] = notification.useNotification();
  const navigate = useNavigate();

  const submitForm = async () => {
    try {
      const formData = await form.validateFields();
      setLoading(true);
      const response = await axios.post("/partnerFrontend", formData);
      if (response.status === 201) {
        message.success(
          "Thank you for submitting your details. Once your registration is approved, you will be intimated through email for activation"
        );
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="partner-with-us">
        <div className="container">
          <div className="mb-35 text-center">
            <img src="/images/logo.png" className="logo-white" />
          </div>

          <div className="boxed">
            <div
              className="bg-image"
              style={{
                backgroundImage: `url(/images/partner-bg.jpg)`,
              }}
            >
              <Link to="/" className="btn-back">
                <i className="fa-solid fa-arrow-left"></i> Back to Home
              </Link>
              <div className="content-place">
                <h2>Sharing the Love of Therapy Animals</h2>
                <p>
                  We provide support and resources for you and your pet to
                  thrive as a registered Pet Partners therapy animal team.
                </p>
              </div>
            </div>
            <div className="bg-content">
              <h3>Partner With us</h3>
              <Form form={form} layout="vertical">
                <Form.Item
                  name="partnerName"
                  rules={[
                    { required: true, message: "Please enter your Name" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-account-outline" />}
                    placeholder="Name"
                  />
                </Form.Item>

                <Form.Item
                  name="storeName"
                  rules={[
                    { required: true, message: "Please enter your Store Name" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-office-building-outline" />}
                    placeholder="Store / Establishment Name"
                  />
                </Form.Item>

                <Form.Item
                  name="categoryId"
                  rules={[
                    { required: true, message: "Please select any category" },
                  ]}
                >
                  <Select placeholder="Select Category">
                    <Select.Option value="petstore">Pet Store</Select.Option>
                    <Select.Option value="vet">Vet</Select.Option>
                    <Select.Option value="trainer">Trainer</Select.Option>
                    <Select.Option value="groomer">Groomer</Select.Option>
                    <Select.Option value="breeder">Breeder</Select.Option>
                    <Select.Option value="others">Others</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="emailId"
                  rules={[
                    { required: true, message: "Please enter your EmailId" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-email-outline" />}
                    placeholder="Email Address"
                  />
                </Form.Item>

                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Phone Number",
                    },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-phone-in-talk-outline" />}
                    placeholder="Phone Number"
                  />
                </Form.Item>

                <Form.Item
                  name="addressLine1"
                  rules={[
                    { required: true, message: "Please enter your Address" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-map-marker-outline" />}
                    placeholder="Address line 1"
                  />
                </Form.Item>

                <Form.Item name="addressLine2">
                  <Input
                    prefix={<i className="mdi mdi-map-marker-outline" />}
                    placeholder="Address line 2"
                  />
                </Form.Item>

                <Form.Item
                  name="city"
                  rules={[
                    { required: true, message: "Please enter your city" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-map-marker-outline" />}
                    placeholder="City"
                  />
                </Form.Item>

                <Form.Item
                  name="pinCode"
                  rules={[
                    { required: true, message: "Please enter your Pin Code" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-map-marker-outline" />}
                    placeholder="Pincode"
                  />
                </Form.Item>

                <Form.Item
                  name="country"
                  rules={[
                    { required: true, message: "Please enter your County" },
                  ]}
                >
                  <Input
                    prefix={<i className="mdi mdi-map-marker-outline" />}
                    placeholder="Country"
                  />
                </Form.Item>

                <Button
                  block
                  type="primary"
                  htmlType="button"
                  onClick={submitForm}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerWithUs;
