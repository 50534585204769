import React, { useEffect, useState } from "react";
import { Space, Table, Button, message, Switch, Popconfirm, Flex } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import Axios from "../../../src/Common/interceptor";
import AddOrEditRole from "./AddOrEditRole";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

function Roles() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [isCreateOrEdit, setIsCreateOrEdit] = useState(false);
  const [roleEdit, setRoleEdit] = useState(null);
  const [data, setData] = useState([]);
  const columns = [
    {
      title: "Role Name",
      dataIndex: "rolename",
      key: "rolename",
    },
    {
      title: "Role Status",
      dataIndex: "rolestatus",
      key: "rolestatus",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const onChange = (id, { rolename, permissions, value }) => {
    axios
      .put("roles/" + id, { rolename, permissions, rolestatus: value })
      .then((response) => {
        if (response) {
          console.log(response);
          message.success("Successfully updated " + rolename);
        }
      })
      .catch((err) => {
        message.error(`Unable to save role.`);
        console.error(err);
      });
  };

  const onRoleEditClick = (_id, rolename, permissions) => {
    setIsCreateOrEdit(true);
    setRoleEdit({ _id, rolename, permissions });
  };

  const cancelAddOrEdit = () => {
    setIsCreateOrEdit(false);
    setRoleEdit(null);
  };

  const confirm = (roleid) => {
    axios
      .delete("roles/" + roleid)
      .then(() => {
        message.success(`Successfully deleted`);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((err) => {
        message.error(`Delete failed`);
        console.error(err);
      });
  };

  const cancel = (rolename) => {
    message.info(`Delete request cancelled for ${rolename}`);
  };

  useEffect(() => {
    axios
      .get("roles")
      .then((response) => {
        if (response?.data?.roles) {
          console.log(response.data.roles);

          const tabledata = response.data.roles.map(
            ({
              _id,
              rolename,
              permissions,
              createdAt,
              updatedAt,
              rolestatus,
            }) => {
              return {
                _id,
                rolename,
                permissions,
                createdAt,
                updatedAt,
                rolestatus: (
                  <Switch
                    defaultChecked={rolestatus ?? false}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={(value) =>
                      onChange(_id, { rolename, permissions, value })
                    }
                  />
                ),
                action: (
                  <Space>
                    <NavLink
                      onClick={() =>
                        onRoleEditClick(_id, rolename, permissions)
                      }
                    >
                      <AiOutlineEdit size={25} />
                    </NavLink>

                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete this task?"
                      onConfirm={() => confirm(_id)}
                      onCancel={() => cancel(rolename)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <NavLink>
                        {" "}
                        <AiOutlineDelete size={25} />{" "}
                      </NavLink>
                    </Popconfirm>
                  </Space>
                ),
              };
            }
          );
          setData(tabledata);
        }
      })
      .catch((err) => {
        message.error(`Unable to load Roles.`);
        console.error(err);
      });
  }, []);

  if (!isCreateOrEdit) {
    return (
      <>
        <Flex
          align="center"
          justify="space-between"
          style={{ marginBottom: 35, marginTop: 25 }}
        >
          <h2 style={{ margin: 0 }}>Roles Management</h2>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setIsCreateOrEdit(true);
                setRoleEdit(null);
              }}
            >
              Create Role
            </Button>
          </div>
        </Flex>

        <Table dataSource={data} columns={columns} />
      </>
    );
  } else {
    let props = {};
    if (roleEdit) {
      const { _id, rolename, permissions } = roleEdit;
      props = { id: _id, rolename, permissions };
    }
    props = { ...props, cancelAddOrEdit };
    return <AddOrEditRole {...props}></AddOrEditRole>;
  }
}

export default Roles;
