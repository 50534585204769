const SiteConfig = {
  facebook: 'http://www.facebook.com',
  twitter: 'http://www.twitter.com',
  linkedIn: 'http://www.linkedin.com',
  instagram: 'http://www.instagram.com',
  darker: '#005249',
  call  : '+91 9940 212424',
  support: 'support@petzdore.in',
  android: 'http://www.playstore.com',
  apple: 'http://www.applestore.com',
};

export default SiteConfig;
