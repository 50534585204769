import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Select, Button, message, Input, Form} from 'antd';
import { useNavigate } from "react-router-dom";
import Axios from '../../../src/Common/interceptor';
import PropTypes from 'prop-types';
import {  LockOutlined } from '@ant-design/icons';

function AddOrEditUser(props) {
  const { id, cancelAddOrEdit } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [allRoles, setAllRoles] = useState(null);

  const onFinish = (values) => {
    if (id) {
      console.log('In edit');
      axios.put('user/' + id, { ...values }).then((response) => {
        if (response) {
          message.success(`Successfully updated`);
          setTimeout(() => {
            navigate(0);
          }, 2000);
          
        }
      }).catch(err => {
        message.error(`Unable to save the user.`);
        console.error(err);
      });
    }
    else {
      axios.post('user', { ...values }).then((response) => {
        if (response) {
          navigate(0);
        }
      }).catch(err => {
        message.error(`Unable to save the User.`);
        console.error(err);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCancelClick = () => {
    cancelAddOrEdit();
  };

  useEffect(() => {

    axios.get('roles').then((response) => {
      if (response?.data?.roles) {
        const roles = response.data.roles.filter(x => x.rolestatus).map(x => {
          return { value: x._id, label: x.rolename }
        });
        setAllRoles(roles);
      }
    })

    if (id) {
      axios.get('user/' + id).then((response) => {
        if (response?.data?.user) {
          console.log(response.data.user);
          const { firstName, lastName, userName, userType, roleId } = response.data.user;
          form.setFieldsValue({
            firstName, lastName, userName, roleId: roleId?.toString(), userType: userType.toString()
          });
        }
      })


    }
  }, []);

  const handleTypeChange = () => { };
  const handleRoleChange = () => { };

  const initialValues = {};
  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}
      onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
      <Row style={{
        justifyContent: "center"
      }}>
        <Col>
          <Card title={id ? "Edit User" : "Create User"} style={{ width: "100%", maxWidth: "560px" }}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: 'Please input FirstName' },
                { min: 4, message: 'FirstName must be minimum 4 characters.' },
                { max: 16, message: 'FirstName cannot exceed 16 characters.' }
              ]}>
              <Input placeholder='First Name' />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: 'Please input Last Name' },
                { min: 4, message: 'Last Name must be minimum 4 characters.' },
                { max: 16, message: 'Last Name cannot exceed 16 characters.' }
              ]}>
              <Input placeholder='Last Name' />
            </Form.Item>

            <Form.Item
              label="Email"
              name="userName"
              rules={[
                { required: true, message: 'Please input Username' },
                { min: 4, message: 'Username must be minimum 4 characters.' },
                { max: 32, message: 'Username cannot exceed 32 characters.' }
              ]}>
              <Input placeholder='Username' />
            </Form.Item>

            {!id && <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input Password!' },
                { min: 4, message: 'NewPassword must be minimum 4 characters.' },
                { max: 16, message: 'NewPassword cannot exceed 16 characters.' }
              ]}
            >
              <Input.Password placeholder='New Password' prefix={<LockOutlined />} />
            </Form.Item>
            }

            <Form.Item
              label="User Type"
              name="userType"
            >
              <Select
                // defaultValue="1"
                style={{ width: 500 }}
                onChange={handleTypeChange}
                options={[
                  { value: "1", label: 'SuperAdmin' },
                  { value: "2", label: 'Admin' },
                  { value: "3", label: 'Manager' },
                  { value: "4", label: 'Executive' },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Role"
              name="roleId"
            >
              <Select
                style={{ width: 500 }}
                onChange={handleRoleChange}
                options={allRoles ? [...allRoles] : []}
              />
            </Form.Item>


            <Button type='primary' htmlType="submit" block>
              Submit
            </Button>

            <Button block onClick={() => navigate(0)} style={{ marginTop: '20px' }}>Back</Button>
          </Card>

        </Col>
      </Row>
    </Form>

  )
}

AddOrEditUser.defaultProps = {
  id: null,
  // rolename: null,
  // permissions: [],
  // cancelAddOrEdit: () => { }
};

AddOrEditUser.propTypes = {
  id: PropTypes.string,
  // rolename: PropTypes.string,
  // permissions: PropTypes.array,
  // cancelAddOrEdit: PropTypes.func
};

export default AddOrEditUser