import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { INR } from "../constants/currency";

const PolicyStatusView = ({ onClose, open, data }) => {
  return (
    <Drawer title="View Detail" placement="right" onClose={onClose} open={open}>
      <div className="view_form">
        <label>Policy Number</label>
        <p>{data.policyNumber}</p>
      </div>
      <div className="view_form">
        <label>Proposal Number</label>
        <p>{data.proposalNumber}</p>
      </div>
      <div className="view_form">
        <label>Proposal number</label>
        <p>{data.proposalNumber}</p>
      </div>
      <div className="view_form">
        <label>Owner Name</label>
        <p>{data.ownerName}</p>
      </div>
      <div className="view_form">
        <label>Owner Contact No</label>
        <p>{data.ownerCantactNo}</p>
      </div>

      <div className="view_form">
        <label>Pet Type</label>
        <p>{data.petType}</p>
      </div>

      <div className="view_form">
        <label>Pet Name</label>
        <p>{data.petName}</p>
      </div>

      <h4>Billing Information</h4>

      <div className="billing-flex">
        <p>
          Total Premium{" "}
          <span>
            {INR} {data.totalPremium}
          </span>
        </p>
        <p>
          SGST Amount{" "}
          <span>
            {INR} {data.sgstAmount}
          </span>
        </p>
        <p>
          CGST Amount{" "}
          <span>
            {INR} {data.cgstAmount}
          </span>
        </p>
        <p>
          iGST Amount{" "}
          <span>
            {INR} {data.igst}
          </span>
        </p>
        <p>
          Net Premium{" "}
          <span>
            {INR} {data.netPremium}
          </span>
        </p>
        <p>
          Final Premium{" "}
          <span>
            {INR} {data.finalPremium}
          </span>
        </p>
      </div>
    </Drawer>
  );
};
export default PolicyStatusView;