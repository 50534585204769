import axios from 'axios';

const storage = (() => {
  let memoryStorage = {};

  return {
    getItem: (key) => {
      try {
        return localStorage.getItem(key);
      } catch (error) {
        return memoryStorage[key] || null;
      }
    },
    setItem: (key, value) => {
      try {
        localStorage.setItem(key, value);
      } catch (error) {
        memoryStorage[key] = value;
      }
    },
    removeItem: (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        delete memoryStorage[key];
      }
    },
    clear: () => {
      try {
        localStorage.clear();
      } catch (error) {
        memoryStorage = {};
      }
    },
  };
})();

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.handleFlowSelection = this.handleFlowSelection.bind(this);
    this.petBreeds = {
      Dog: ['Labrador', 'Poodle', 'German Shepherd'],
      Cat: ['Persian', 'Siamese', 'Maine Coon'],
    };
    this.paymentInProgress = false; // Add a flag to prevent repeated execution
  }

  async handleFlowSelection(flow) {
    this.reply(flow);
  }

  async reply(message) {
    const apiUrl = process.env.REACT_APP_NODE_ENDPOINT + '/dialogflow';
    const enquiryUrl =
      process.env.REACT_APP_NODE_ENDPOINT + '/enquiry/chatbotenquiry';
    const breedList =
      process.env.REACT_APP_NODE_ENDPOINT + '/enquiry/breedlist';
    const proposalUrl =
      process.env.REACT_APP_NODE_ENDPOINT + '/enquiry/generateproposal';

    if (storage.getItem('flow') === 'Have Quote Ref. No.') {
      const questions = storage.getItem('questionaire').split(',');
      if (questions[0] === 'Quote Ref. No?') {
        if (message.startsWith('E00')) {
          storage.setItem('enquiryNumber', message);
          const questions = storage.getItem('questionaire').split(',');
          storage.setItem('questionaire', questions.slice(1).join(','));
          return this.askNextQuestion();
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_NODE_ENDPOINT}/enquiry/list/?search=${message}`
          );
          if (response.data.statusCode === 200) {
            const enquiryIds = response.data?.allEnquirys?.map((enquiry) => {
              return {
                value: String(enquiry.enquiryNumber),
                text: enquiry.enquiryNumber,
              };
            });
            let result;
            if (enquiryIds.length === 0) {
              result = this.createChatBotMessage('No Enquiry Found', {});
            } else {
              result = this.createChatBotMessage('Choose the enquiry', {
                widget: 'options',
                withAvatar: true,
                delay: 500,
                loading: true,
                payload: {
                  buttonOptions: enquiryIds,
                },
              });
            }
            return this.updateChatbotState(result);
          }
        }
      } else if (questions[0] === 'Cancel') {
        if (message === 'Yes') {
          const postData = {
            enquiryNumber: storage.getItem('enquiryNumber'),
          };
          const response = await axios
            .put(proposalUrl, postData)
            .then((res) => {
              if (res.data.statusCode === 201) {
                this.paymentInProgress = true; // Set the flag
                const paymentUrl =
                  process.env.REACT_APP_NODE_ENDPOINT + '/enquiry/paymenturl';

                try {
                  setTimeout(() => {
                    const response = axios
                      .get(
                        `${paymentUrl}?proposalNumber=${res.data.proposal.proposalNumber}`
                      )
                      .then((res) => {
                        const paymentMessage = this.createChatBotMessage(
                          'Proposal created successfully. Kindly proceed with payment:',
                          {
                            widget: 'paymentLink',
                            withAvatar: true,
                            delay: 500,
                            loading: true,
                            payload: {
                              url: res.data.paymentUrl,
                            },
                          }
                        );
                        this.updateChatbotState(paymentMessage);
                      });
                    storage.clear();
                    setTimeout(() => {
                      const confirmationMessage = this.createChatBotMessage(
                        'Is the payment done or continue to home?',
                        {
                          widget: 'options',
                          withAvatar: true,
                          delay: 500,
                          loading: true,
                          payload: {
                            buttonOptions: [
                              { text: 'Yes', value: 'Done' },
                              { text: 'Home', value: 'Home' },
                            ],
                          },
                        }
                      );
                      this.updateChatbotState(confirmationMessage);
                      this.paymentInProgress = false; // Reset the flag
                    }, 5000);
                  }, 2000);

                  storage.removeItem('questionaire');
                } catch (error) {
                  console.error('Error fetching payment URL:', error.message);
                  this.paymentInProgress = false; // Reset the flag in case of error
                }
                return;
              }
            });
        } else {
          const result = this.createChatBotMessage(
            'Thanks for using PetzAdoreBot'
          );
          this.updateChatbotState(result);
          storage.clear();
        }
        return this.askNextQuestion();
      }
    }
    if (message === 'done') {
      storage.clear();
    }
    if (
      message === 'Claims' ||
      message === 'Have Quote Ref. No.' ||
      message === 'New Insurance'
    ) {
      if (message === 'New Insurance') {
        const response = await axios.get(breedList);
        if (response.data.statusCode === 200) {
          this.petBreeds['Dog'] = response.data.petbreeds;
          storage.setItem('petBreeds', JSON.stringify(this.petBreeds));
        }
      }
      storage.setItem('flow', message);
    } else if (message === 'proposal') {
      const postData = {
        enquiryNumber: storage.getItem('enquiryNumber'),
      };
      const response = await axios.put(proposalUrl, postData);

      if (response.data.statusCode === 201) {
        const result = this.createChatBotMessage(
          'Proposal created successfully. Kindly proceed with payment'
        );
        storage.setItem(
          'proposalNumber',
          response.data.proposal.proposalNumber
        );
        this.updateChatbotState(result);
      }
    } else if (!storage.getItem('flow')) {
      const result = this.createChatBotMessage('Please select a flow', {
        widget: 'options',
        withAvatar: true,
        delay: 500,
        loading: true,
        payload: {
          buttonOptions: [
            { text: 'New Insurance', value: 'New Insurance', id: 1 },
            {
              text: 'Have Quote Ref. No.',
              value: 'Have Quote Ref. No.',
              id: 2,
            },
            { text: 'Claims', value: 'Claims', id: 3 },
          ],
        },
      });

      this.updateChatbotState(result);
      return;
    }

    // if (storage.getItem('proposalNumber') && !this.paymentInProgress) {

    // }

    const flowChosen = storage.getItem('flow');
    if (
      flowChosen &&
      (message === 'New Insurance' ||
        message === 'Have Quote Ref. No.' ||
        message === 'Claims')
    ) {
      const postData = {
        languageCode: 'en',
        sessionId: '12345678',
        queryText:
          flowChosen === 'New Insurance'
            ? '1'
            : flowChosen === 'Have Quote Ref. No.'
            ? '2'
            : flowChosen === 'Claims'
            ? '3'
            : '0',
        flow:
          flowChosen === 'New Insurance'
            ? '1'
            : flowChosen === 'Have Quote Ref. No.'
            ? '2'
            : flowChosen === 'Claims'
            ? '3'
            : '0',
      };

      try {
        const response = await axios.post(apiUrl, postData);
        storage.setItem('questionaire', response.data.join(','));
        this.askNextQuestion();
      } catch (error) {
        console.error('Error fetching questionnaire:', error.message);
      }
    } else if (
      storage.getItem('questionaire') === '' &&
      storage.getItem('flow') !== 'Have Quote Ref. No.'
    ) {
      const inputs = storage.getItem('answers') + ',' + message;

      try {
        const res = await axios.post(enquiryUrl, { enquirydetails: inputs });
        const msg = this.createChatBotMessage(
          'Please wait, saving the details'
        );
        this.updateChatbotState(msg);

        setTimeout(() => {
          storage.clear();
          const result = this.createChatBotMessage(
            `Thank you for giving your details. Your enquiry ref number is ${res.data?.enquiry?.enquiryNumber}. Please quote this number in your further communications. The best offer for your pet is sent to your email id. You may check and proceed further. For any other clarifications, please mail to support@petzdore.in. Thank you`
          );
          this.updateChatbotState(result);
          storage.setItem('enquiryNumber', res.data?.enquiry?.enquiryNumber);
          this.showAdditionalOptions();
        }, 2000);
      } catch (error) {
        console.error('Error submitting enquiry:', error.message);
      }
    } else if (
      storage.getItem('flow') &&
      storage.getItem('questionaire') &&
      storage.getItem('questionaire') !== ''
    ) {
      this.askNextQuestion(message);
    }
  }

  askNextQuestion(message = null) {
    if (message) {
      let answers = storage.getItem('answers');
      answers =
        (answers
          ? answers + ','
          : (storage.getItem('flow') === 'New Insurance'
              ? 1
              : storage.getItem('flow') === 'Have Quote Ref. No.'
              ? 2
              : storage.getItem('flow') === 'Claims'
              ? 3
              : 4) + ',') + message;
      if (storage.getItem('breeds')) {
        const breeds = JSON.parse(storage.getItem('breeds'));
        if (breeds[0].text === 'Persian') {
          answers = answers + ',BIG';
          storage.setItem('answers', answers);
          storage.removeItem('breeds');
        } else {
          answers =
            answers +
            ',' +
            breeds.find((breed) => breed.text === message).categoryName;
          storage.setItem('answers', answers);
          storage.removeItem('breeds');
          this.createChatBotMessage('Chosen breed size is ' + message, {
            withAvatar: false,
            delay: 200,
          });
        }
      }
      // else {
      //   return this.createChatBotMessage('No Breeds found ');
      // }
      storage.setItem('answers', answers);
    }

    const questions = storage.getItem('questionaire')
      ? storage.getItem('questionaire').split(',')
      : [];

    if (questions.length > 0 && questions[0] !== '') {
      const buttonQuestions = [
        'Select your pet type',
        'Select the gender of your pet',
        'Select the breed of your pet',
        'Is your pet registered?',
        'Is your pet healthy?',
        'Is your Pet vaccinated?',
        'Is Pet spayed or castrated?',
        'Quote Ref. No?',
        'Proceed to generate proposal',
      ];

      let result;
      if (questions[0] === 'Select your pet type') {
        result = this.createChatBotMessage(questions[0], {
          widget: 'options',
          withAvatar: true,
          delay: 500,
          loading: true,
          payload: {
            buttonOptions: [
              { text: 'Cat', value: 'Cat' },
              { text: 'Dog', value: 'Dog' },
            ],
          },
        });
      } else if (questions[0] === 'Select the gender of your pet') {
        result = this.createChatBotMessage(questions[0], {
          widget: 'options',
          withAvatar: true,
          delay: 500,
          loading: true,
          payload: {
            buttonOptions: [
              { text: 'Male', value: 'Male' },
              { text: 'Female', value: 'Female' },
            ],
          },
        });
      } else if (questions[0] === 'Select the breed of your pet') {
        const answers = storage.getItem('answers').split(',');
        const petType = answers[8];
        const dogBreeds = JSON.parse(storage.getItem('petBreeds'));
        const breeds =
          petType === 'Dog'
            ? dogBreeds?.Dog?.map((breed) => ({
                text: breed.petbreedName,
                value: breed.petbreedName,
                categoryName: breed.categoryName,
              }))
            : this.petBreeds[petType]?.map((breed) => ({
                text: breed,
                value: breed,
              }));
        storage.setItem('breeds', JSON.stringify(breeds));

        result = this.createChatBotMessage(questions[0], {
          widget: 'options',
          withAvatar: true,
          delay: 500,
          loading: true,
          payload: {
            buttonOptions: breeds,
          },
        });
      } else if (questions[0] === 'Is your pet healthy?') {
        result = this.createChatBotMessage(questions[0], {
          widget: 'options',
          withAvatar: true,
          delay: 500,
          loading: true,
          payload: {
            buttonOptions: [
              { text: 'Healthy', value: 'Healthy' },
              { text: 'Minor health issues', value: 'Minor health issues' },
              { text: 'Has health issues', value: 'Has health issues' },
            ],
          },
        });
      } else if (buttonQuestions.includes(questions[0])) {
        result = this.createChatBotMessage(questions[0], {
          widget: 'options',
          withAvatar: true,
          delay: 500,
          loading: true,
          payload: {
            buttonOptions: [
              { text: 'Yes', value: 'Yes' },
              { text: 'No', value: 'No' },
            ],
          },
        });
      } else {
        result = this.createChatBotMessage(questions[0]);
      }

      this.updateChatbotState(result);
      questions.shift();
      storage.setItem('questionaire', questions.join(','));
    } else {
      storage.setItem('questionaire', '');
      this.reply('');
    }
  }

  showAdditionalOptions() {
    const additionalOptions = [
      { text: 'Continue with Enquiry', value: 'proposal', id: 2 },
      { text: 'Claims', value: '3', id: 3 },
    ];

    const optionsMessage = this.createChatBotMessage(
      'Please choose an option:',
      {
        widget: 'options',
        withAvatar: true,
        delay: 500,
        loading: true,
        payload: { buttonOptions: additionalOptions },
      }
    );
    this.updateChatbotState(optionsMessage);
  }

  updateChatbotState(message) {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }

  addMessage(message) {
    this.updateChatbotState(
      this.createChatBotMessage(message.text, { withAvatar: false, delay: 500 })
    );
  }
}

export default ActionProvider;
