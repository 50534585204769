import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Space, message } from "antd";
import Axios from "../../src/Common/interceptor";
import View from "./policyStatusView";
import { INR } from "../constants/currency";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

function PolicyStatus() {
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [open, setOpen] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [policyDetailes, setPolicyDetailes] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [filteredPolicies, setFilteredPolicies] = useState(null);

  useEffect(() => {
    axios
      .get("policy")
      .then((response) => {
        if (response?.data?.allPolicys.length > 0) {
          const policyList = 
          response.data.allPolicys.map((policy) => {
            return {
              ...policy,
              totalPremium:
                policy.totalPremium && `${INR} ${policy.totalPremium}`,
              key: policy._id,
            };
          })
          setPolicies(policyList);
          setFilteredPolicies(policyList);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(()=>{
    if (dateRange?.length === 2) {
      const filtered = policies.filter((policy) => {
        const createdAt = moment(policy.policyDate);
        return createdAt.isBetween(
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
          null,
          "[]"
        );
      });
      setFilteredPolicies(filtered);
    } else {
      setFilteredPolicies(policies);
    }
  },[dateRange])

  const showDrawer = (record) => {
    setOpen(true);
    axios
      .get(`policy/${record._id}`)
      .then((response) => {
        if (response?.data?.policy) {
          console.log("response?.data?.policy--", response?.data?.policy);
          setPolicyDetailes({ ...response.data.policy[0] });
        }
      })
      .catch((err) => {
        message.error("Unable to get policy details.");
        console.error(err);
      });
  };
  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Proposal Number",
      dataIndex: "proposalNumber",
      key: "proposalNumber",
    },
    {
      title: "Insurer Name",
      dataIndex: "insurerName",
      key: "insurerName",
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
    },
    {
      title: "Policy expiry Date",
      dataIndex: "policyexpiryDate",
      key: "policyexpiryDate",
    },
    {
      title: "Pet Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Pet Name",
      dataIndex: "petName",
      key: "petName",
    },
    {
      title: "Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "Partner Code",
      dataIndex: "partnerCode",
      key: "partnerCode",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button
            type="primary"
            className="view_button"
            onClick={() => showDrawer(record)}
          >
            View Policy
          </Button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      policy: "555855668",
      proposal: "54855855",
      ownername: "Mike",
      petname: "Puppy",
      total: "2568",
    },
    {
      key: "2",
      policy: "555855668",
      proposal: "54855855",
      ownername: "Mike",
      petname: "Puppy",
      total: "2568",
    },

    {
      key: "3",
      policy: "555855668",
      proposal: "54855855",
      ownername: "Mike",
      petname: "Puppy",
      total: "2568",
    },
    {
      key: "4",
      policy: "555855668",
      proposal: "54855855",
      ownername: "Mike",
      petname: "Puppy",
      total: "2568",
    },
  ];
  const handleDownload = async (downloadType) => {
    try {
      if (filteredPolicies.length > 0) {
        const url =
          dateRange.length > 0
            ? `${
                process.env.REACT_APP_NODE_ENDPOINT
              }/policy?download_type=${downloadType}&from_date=${dateRange[0].format(
                "YYYY-MM-DD"
              )}&to_date=${dateRange[1].format("YYYY-MM-DD")}`
            : `${process.env.REACT_APP_NODE_ENDPOINT}/policy?download_type=${downloadType}`;

        const response = await axios.get(url);

        if (response.status !== 200) {
          throw new Error(
            `Failed to download file. Status: ${response.status}`
          );
        }

        const downloadLink = response.data.downloadLink;
        if (downloadType === 2) {
          window.open(downloadLink, "_blank");
        } else {
          const downloadAnchor = document.createElement("a");
          downloadAnchor.href = downloadLink;
          const filename = downloadLink
            ? downloadLink.substring(downloadLink.lastIndexOf("/") + 1)
            : "downloaded_file";
          downloadAnchor.download = filename;
          downloadAnchor.style.display = "none";

          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };

  const handleRangePickerChange = (dates) => {
    setDateRange(dates);
  };

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0, marginRight: 25 }}>Policy Status</h2>
        <RangePicker
          size="middle"
          style={{ marginRight: "auto" }}
          onChange={handleRangePickerChange}
        />

        <div>
          <Button
            disabled={filteredPolicies?.length === 0}
            onClick={() => handleDownload(2)}
            type="default"
          >
            Download as PDF
          </Button>
          <Button
            disabled={filteredPolicies?.length === 0}
            onClick={() => handleDownload(1)}
            type="default"
          >
            Download as Excel
          </Button>
        </div>
      </Flex>
      <Table columns={columns} dataSource={filteredPolicies} />
      <View onClose={onClose} open={open} data={policyDetailes} />
    </>
  );
}
export default PolicyStatus;
